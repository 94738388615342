<style>
div.instructionsContent {
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: visibility 0s, opacity 0.5s linear;
  display: flex;
  flex-direction: row;
}

div.visible {
  visibility: visible;
  opacity: 1;
  height: auto;
}

div.minimize {
  flex-direction: column;
}
</style>

<template>
  <fieldset style="margin-top: 48px; margin-bottom: 48px">
    <div style="display: flex">
      <div style="flex-basis: 100%"></div>
      <div style="flex-basis: 100%; font-size: 14px; font-weight: bold">
        Instructions
      </div>
      <div style="flex-basis: 100%; text-align: right">
        <IconContainer @click.stop="toggleDisplay">
          <IconComponent :icon="caretIcon" />
        </IconContainer>
      </div>
    </div>
    <div
      class="instructionsContent"
      :class="{ visible: displayInstructions, minimize: minimize }"
    >
      <div
        style="
          margin-top: 5px;
          margin-bottom: 0px;
          margin-left: 15px;
          margin-right: 10px;
        "
      >
        <img
          src="../../assets/solution_illustration.png"
          style="width: 422px; vertical-align: middle"
        />
      </div>
      <div style="flex-grow: 1; margin-right: 15px">
        <p style="text-align: left">
          <span style="font-weight: bold">Part weight</span><br />
          The weight of one part of a certain type used in the packaging.
          (Example: A carton board part weighs 0.1 kg.)
        </p>
        <p style="text-align: left">
          <span style="font-weight: bold">Parts per package</span><br />
          How many parts of a certain type are there in the packaging. (Example:
          There are 6 carton board parts used in the packaging.)
        </p>
        <p style="text-align: left">
          <span style="font-weight: bold">Products per packaging</span><br />
          How many units of the product are there in the packaging. (Example:
          There are 6 product units in the packaging.)
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
import IconComponent from "@/components/common/IconComponent.vue";
import styled from "vue3-styled-components";

const IconContainer = styled("div", {})`
  position: relative;
  width: 32px;
  margin-right: 0px;
  margin-left: auto;
  color: #999;

  &:hover {
    color: #000;
  }
`;

export default {
  name: "SolutionEditorInstructions",
  components: {
    IconComponent,
    IconContainer,
  },
  data() {
    return {
      displayInstructions: true,
    };
  },
  computed: {
    windowWidth() {
      return this.$store.getters["window/getWidth"];
    },
    minimize() {
      return this.windowWidth < 750;
    },
    caretIcon() {
      if (this.displayInstructions) {
        return "caret-up";
      }
      return "caret-down";
    },
  },
  methods: {
    toggleDisplay() {
      this.displayInstructions = !this.displayInstructions;
    },
  },
};
</script>
