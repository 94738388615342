<template>
  <div v-if="requestsExist">
    <fieldset
      style="
        width: 600px;
        max-height: 55px;
        margin-left: auto;
        margin-right: auto;
      "
    >
      <legend>Solution</legend>
      <treeselect
        v-model="selectedSolutionId"
        :options="options"
        :disable-branch-nodes="true"
      />
    </fieldset>

    <SalesSolution v-if="hasSelectedSolution" />
    <ActionButton text="Generate PDF" @click="handleClickGeneratePDF" />
    <ActionButton
      text="Close contact request"
      @click="handleCloseSolutionRequest"
    />
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div style="text-align: center; width: 800px">
          <SalesSolution />
        </div>
      </template>
    </vue3-html2pdf>
  </div>
  <div v-if="!requestsExist">
    <p>No open consultation requests exist!</p>
  </div>
</template>

<script>
/* eslint-disable */

import ActionButton from "@/components/ui/ActionButton.vue";
import CloseSolutionRequestModal from "@/components/modals/CloseSolutionRequestModal.vue";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";
import SalesSolution from "@/views/SalesView/SalesSolution.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

import SalesService from "@/services/sales.service";

import { $vfm } from "vue-final-modal";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

export default {
  name: "SalesView",
  components: {
    ActionButton,
    DropdownMenu,
    SalesSolution,
    Treeselect,
    Vue3Html2pdf,
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    return { toast, store };
  },
  created() {
    this.$store.dispatch("sales/fetchData");
  },
  data() {
    return {
      selectedSolutionId: null,
    };
  },
  computed: {
    options() {
      return this.$store.getters["sales/getOptions"];
    },
    selectedSolution() {
      return this.$store.getters["sales/getSelectedSolution"];
    },
    hasSelectedSolution() {
      if (this.selectedSolutionId) {
        return true;
      }
      return false;
    },
    requestsExist() {
      return this.$store.getters["sales/getRequestsExist"];
    },
    filename() {
      return this.$store.getters["sales/getSelectedSolutionFilename"];
    },
  },
  watch: {
    selectedSolutionId(newId, oldId) {
      this.$store.dispatch("sales/selectSolution", newId);
    },
  },
  methods: {
    handleCloseSolutionRequest() {
      const closingSolution = this.selectedSolution;
      const toast = this.toast;
      const store = this.store;
      const resetSelection = this.resetSelection;
      $vfm.show({
        component: CloseSolutionRequestModal,
        bind: {
          closingSolution: closingSolution,
          resetSelection: resetSelection,
        },
        on: {
          close() {
            SalesService.closeRequestForSolution(closingSolution.solutionId)
              .then(() => {
                toast.success("Request closed successfully!");
                resetSelection();
                store.dispatch("sales/fetchData");
              })
              .catch((e) => {
                console.error(e);
              });
          },
        },
      });
    },
    resetSelection() {
      this.selectedSolutionId = null;
    },
    handleClickGeneratePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
