<template>
  <div style="height: 40px">
    <h1 style="margin: 5px">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "TitleComponent",
  computed: {
    title() {
      let result = "";
      if (this.$route.meta.title) {
        result = this.$route.meta.title;
      }
      return result;
    },
  },
};
</script>
