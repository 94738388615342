<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div class="form-fields-container">
        <SingleRowField label="E-mail" v-model="email" name="email" />
      </div>
      <div class="form-buttons-container">
        <ActionButton text="Reset password" icon="reset" v-if="!hideButton" />
      </div>
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import UserService from "@/services/user.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

export default {
  name: "ForgotPasswordForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be a valid email"),
    });
    return {
      schema,
      email: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      UserService.requestPasswordReset({ email: values.email })
        .then(() => {
          this.toast.info(
            "Request sent. If a user exists with the given email address, a password reset link will be sent into that address."
          );
        })
        .then(() => {
          this.$emit("submit");
        })
        .catch((error) => {
          this.toast.error(error.response.data.detail);
        });
    },
  },
};
</script>
