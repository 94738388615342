<template>
  <div
    style="
      z-index: 3;
      width: 100%;
      height: 71px;
      left: 0;
      top: 0;
      padding: 0px;
      background-color: #00173a;
      position: fixed;
    "
  >
    <nav
      style="
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-grow: 1;
      "
    >
      <div
        style="display: flex; width: 500px; padding-top: 0px; margin-top: 0px"
      >
        <HomeIconContainer @click="$router.push({ name: 'home' })">
          <img
            style="margin-top: 0px; padding-top: 0px"
            src="../../assets/header-icon.svg"
          />
        </HomeIconContainer>
      </div>
      <div style="display: flex; width: 500px; padding-top: 10px">
        <NavLink v-if="loggedIn" title="Calculator" name="solution" />
        <NavLink
          v-if="hasSolutions"
          title="My packaging solutions"
          name="solutionList"
        />
        <NavLink v-if="hasSalesRights" title="Sales" name="sales" />
      </div>
      <div
        style="
          display: flex;
          width: 500px;
          padding-top: 10px;
          justify-content: flex-end;
        "
      >
        <div
          v-if="loggedIn"
          style="display: flex; cursor: pointer"
          @click="displayUserMenu"
        >
          <div style="margin-right: 0px">
            <img style="margin-top: 15px" src="../../assets/icon-user.svg" />
          </div>
          <div style="margin-left: 10px; margin-right: 33px">
            <p style="vertical-align: middle">{{ username }}</p>
          </div>
        </div>
        <div
          v-if="!loggedIn"
          style="display: flex; cursor: pointer"
          @click="$router.push('login')"
        >
          <div style="margin-right: 0px">
            <img style="margin-top: 15px" src="../../assets/icon-user.svg" />
          </div>
          <div style="margin-left: 10px; margin-right: 33px">
            <p style="vertical-align: middle">Log in</p>
          </div>
        </div>
        <DropdownActionMenu
          ref="userMenu"
          :options="userOptions"
          @changePassword="handleChangePassword"
          @logout="handleLogout"
          @deleteUser="handleDeleteUser"
        />
      </div>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */
import NavLink from "@/components/layout/NavLink.vue";
import styled from "vue3-styled-components";
import DropdownActionMenu from "@/components/ui/DropdownActionMenu.vue";

const IconsContainer = styled("div")`
  left: 0;
  right: 0;
  flex-basis: 100%;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-grow: 1;
`;

const HomeIconContainer = styled("div")`
  margin-left: 33px;
  margin-right: 0px;
  margin-top: 0px;
  padding-top: 0px;
  cursor: pointer;
`;

export default {
  name: "NavComponent",
  components: {
    IconsContainer,
    HomeIconContainer,
    NavLink,
    DropdownActionMenu,
  },
  emits: ["clickLogOut", "clickDeleteUser", "clickNewSolution"],
  data() {
    const userOptions = [
      {
        title: "Change password",
        event: "changePassword",
      },
      {
        title: "Logout",
        event: "logout",
      },
      {
        title: "Delete account",
        event: "deleteUser",
      },
    ];
    return {
      minimizeCutoff: 500,
      userOptions,
    };
  },
  computed: {
    windowWidth() {
      return this.$store.getters["window/getWidth"];
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    hasSalesRights() {
      let user = this.$store.getters["user/get"];
      if (user.sys_role == "Sales") {
        return true;
      }
      return false;
    },
    username() {
      return this.$store.getters["user/getUsername"];
    },
    hasSolutions() {
      return (
        this.$store.getters["auth/loggedIn"] &&
        this.$store.getters["userSolutions/hasSolutions"]
      );
    },
  },
  methods: {
    displayUserMenu() {
      this.$refs.userMenu.display();
    },
    handleChangePassword() {
      this.$router.push({ name: "changePassword" });
    },
    handleLogout() {
      this.$emit("clickLogOut");
    },
    handleDeleteUser() {
      this.$emit("clickDeleteUser");
    },
  },
};
</script>
