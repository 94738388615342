<template>
  <div style="padding-top: 80px">
    <StyledCard>
      <h2>Sign up</h2>
      <SignupForm @submit="handleSignup" />
    </StyledCard>
  </div>
</template>

<script>
import { StyledCard } from "@/components/styled";
import SignupForm from "@/components/forms/SignupForm.vue";

export default {
  name: "SignUpView",
  components: {
    SignupForm,
    StyledCard,
  },
  methods: {
    handleSignup() {
      this.$router.push({
        name: "solution",
      });
    },
  },
};
</script>
