<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div class="form-fields-container">
        <SingleRowField label="Password" name="new_password" type="password" />
        <SingleRowField
          label="Confirm password"
          name="confirm_password"
          type="password"
        />
      </div>
      <div class="form-buttons-container">
        <ActionButton text="Reset password" icon="reset" v-if="!hideButton" />
      </div>
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import UserService from "@/services/user.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

export default {
  name: "ResetPasswordForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
    token: String,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      new_password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirm_password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .oneOf([yup.ref("new_password"), null], "Passwords must match"),
    });
    return {
      schema,
      new_password: "",
      confirm_password: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      UserService.changePasswordWithResetToken(this.token, {
        new_password: values.new_password,
        confirm_password: values.confirm_password,
      })
        .then(() => {
          this.toast.success(
            "Password changed successfully! You may now log in with your new password."
          );
          this.$emit("submit");
        })
        .catch((e) => {
          if (e.response.status == 429) {
            this.toast.error(
              "Too many requests in a short time. Try again after a short while."
            );
          } else {
            this.toast.error(
              "An error occurred while trying to change your password."
            );
          }
        });
    },
  },
};
</script>
