import SolutionPackagingMaterial from "@/models/solution-packaging-material";

export default class Solution {
  constructor(args) {
    this.partsCombinedTotal = 0;
    this.CO2ToTotalPartsWeight = 0;
    this.CO2EqPerProduct = 0;
    this.selectedForComparison = false;
    this.status = {};
    this.packagingMaterials = [];
    if (args) {
      this.solutionId = args.solution_id || "";
      this.name = args.name || "";
      this.productsPerPackaging = args.products_per_packaging || 0;
      if (args.packaging_materials) {
        args.packaging_materials.forEach((materialData) => {
          let material = new SolutionPackagingMaterial(materialData);
          this.packagingMaterials.push(material);
        });
      }
    } else {
      this.name = "";
    }
  }

  addPackagingMaterial(material) {
    this.packagingMaterials.push(material);
  }

  getPartsCombinedTotal() {
    let total = 0;
    this.packagingMaterials.forEach((material) => {
      total += material.parts * material.getTotalCO2();
    });
    return total;
  }

  getPartsCombinedTotalFormatted() {
    let total = this.getPartsCombinedTotal();
    if (isNaN(total) || !isFinite(total)) {
      return "---";
    }
    return +total.toFixed(4);
  }

  getCO2ToTotalPartsWeight() {
    let divisor = 0;
    this.packagingMaterials.forEach((material) => {
      divisor += parseFloat(material.weight);
    });
    return this.getPartsCombinedTotal() / divisor;
  }

  getCO2EqPerProduct() {
    return this.getPartsCombinedTotal() / this.productsPerPackaging;
  }

  getCO2EqPerProductFormatted() {
    let result = this.getCO2EqPerProduct();
    if (isNaN(result) || !isFinite(result)) {
      return "---";
    }
    return +result.toFixed(4);
  }

  calculate() {
    this.partsCombinedTotal = Number(this.getPartsCombinedTotal().toFixed(4));
    this.CO2ToTotalPartsWeight = Number(
      this.getCO2ToTotalPartsWeight().toFixed(0)
    );
    this.CO2EqPerProduct = Number(this.getCO2EqPerProduct().toFixed(4));
  }

  checkStatus() {
    if (this.packagingMaterials.length == 0) {
      this.status = {
        isCalculable: false,
        message: "Your solution has no packaging materials!",
      };
      return false;
    }
    var allMaterialsHaveParts = this.packagingMaterials.every((material) => {
      if (material.parts == 0) {
        return false;
      }
      return true;
    });
    if (!allMaterialsHaveParts) {
      this.status = {
        isCalculable: false,
        message:
          "All materials must have an amount of parts greater than zero!",
      };
      return false;
    }

    var allMaterialsHaveWeight = this.packagingMaterials.every((material) => {
      if (material.weight == 0) {
        return false;
      }
      return true;
    });
    if (!allMaterialsHaveWeight) {
      this.status = {
        isCalculable: false,
        message: "All materials must have a weight greater than zero!",
      };
      return false;
    }

    if (this.productsPerPackaging == 0) {
      this.status = {
        isCalculable: false,
        message: "Products per packaging must be greater than zero!",
      };
      return false;
    }

    this.status = {
      isCalculable: true,
      message: "OK",
    };
    return true;
  }

  mapToSubmitRequest() {
    let data = {
      solution_id: this.solutionId,
      name: this.name,
      products_per_packaging: this.productsPerPackaging,
      packaging_materials: [],
    };
    if (!data.name) {
      data.name = "(unnamed solution)";
    }
    let i = 1;
    this.packagingMaterials.forEach((material) => {
      let materialData = {
        packaging_material_id: material.packaging_material_id,
        weight: material.weight,
        parts: material.parts,
        order: i,
      };
      data.packaging_materials.push(materialData);
      i++;
    });
    return data;
  }
}
