import api from "./api";
const API_PATH = "/v1/solutions/";

class SolutionService {
  getSolution(solutionId) {
    return api.get(API_PATH + solutionId);
  }

  getMySolutions() {
    return api.get(API_PATH);
  }

  submitSolution(data) {
    return api.post(API_PATH + "submit", data);
  }

  postSolution(data) {
    return api.post(API_PATH, data);
  }

  copySolution(solutionId) {
    return api.post(API_PATH + "copy/" + solutionId, null);
  }

  saveSolution(data, CSRFToken) {
    if (data.solution_id) {
      return api.put(API_PATH + data.solution_id, data, {
        headers: { "X-CSRF-Token": CSRFToken },
      });
    } else {
      return api.post(API_PATH, data);
    }
  }

  deleteSolution(solution_id, CSRFToken) {
    return api.delete(API_PATH + solution_id, {
      headers: { "X-CSRF-Token": CSRFToken },
    });
  }

  postSolutionWithContactRequest(data, email) {
    data.email = email;
    return api.post(API_PATH + "request-contact", data);
  }

  addContactRequestToSolution(solution_id, email, CSRFToken) {
    let data = { email: email };
    return api.put(API_PATH + "request-contact/" + solution_id, data, {
      headers: { "X-CSRF-Token": CSRFToken },
    });
  }

  addContactRequestToAllSolutions(email, CSRFToken) {
    let data = { email: email };
    return api.put(API_PATH + "request-contact/all", data, {
      headers: { "X-CSRF-Token": CSRFToken },
    });
  }
}

export default new SolutionService();
