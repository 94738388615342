/* eslint-disable */
import Solution from "@/models/solution";
import SalesService from "@/services/sales.service";

export const sales = {
  namespaced: true,
  state: {
    users: [],
    options: [],
    requestsExist: false,
    selectedSolutionId: null,
    selectedSolution: new Solution(),
    selectedSolutionFilename: "",
  },
  actions: {
    async fetchData({ commit }) {
      let users = [];
      let options = [];
      let requestsExist = false;
      await SalesService.getAllUsers()
        .then((response) => {
          response.data.forEach((user) => {
            let newUser = {};
            newUser.userId = user.user_id;
            newUser.name = user.username;
            newUser.solutions = user.solutions;
            users.push(newUser);
          });
        })
        .catch((e) => {
          console.error(e);
        });
      await SalesService.getAllSolutionsWithoutUser().then((response) => {
        if (response.status == 200) {
          let noUser = {
            name: "(submitted without user)",
            solutions: response.data,
          };
          users.unshift(noUser);
        }
      });
      if (users.length > 0) {
        requestsExist = true;
        users.forEach((user, index) => {
          if (user.solutions.length > 0) {
            let newOption = {
              id: "o" + index.toString(),
              label: user.name,
              children: [],
            };
            user.solutions.forEach((solution, sIndex) => {
              let newChild = {
                id: "o" + index.toString() + "_" + "s" + sIndex.toString(),
                label: solution.name,
              };
              newOption.children.push(newChild);
            });
            options.push(newOption);
          }
        });
      }
      commit("saveUsers", users);
      commit("saveOptions", options);
      commit("saveRequestsExist", requestsExist);
    },
    selectSolution({ commit, state }, id) {
      let solution = new Solution();
      let filename = "";
      if (id) {
        let splitId = id.split(/[^0-9.]/);
        if (splitId.length == 4) {
          commit("saveSelectedSolutionId", id);
          solution = new Solution(
            state.users[splitId[1]].solutions[splitId[3]]
          );
          solution.calculate();
          if (state.users[splitId[1]].userId) {
            filename = state.users[splitId[1]].name + " - " + solution.name;
          } else {
            filename = solution.name;
          }
        }
      }
      commit("saveSelectedSolutionFilename", filename);
      commit("saveSelectedSolution", solution);
    },
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getOptions(state) {
      return state.options;
    },
    getSelectedSolution(state) {
      return state.selectedSolution;
    },
    getRequestsExist(state) {
      return state.requestsExist;
    },
    getSelectedSolutionFilename(state) {
      return state.selectedSolutionFilename;
    },
  },
  mutations: {
    saveUsers(state, users) {
      state.users = users;
    },
    saveOptions(state, options) {
      state.options = options;
    },
    saveSelectedSolutionId(state, id) {
      state.selectedSolutionId = id;
    },
    saveSelectedSolution(state, solution) {
      state.selectedSolution = solution;
    },
    saveRequestsExist(state, requestsExist) {
      state.requestsExist = requestsExist;
    },
    saveSelectedSolutionFilename(state, filename) {
      state.selectedSolutionFilename = filename;
    },
  },
};
