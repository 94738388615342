<template>
  <div
    class="dropdown_menu_container"
    v-if="visible"
    v-click-outside="onClickOutside"
  >
    <div
      class="dropdown_menu_item"
      v-for="option in options"
      :key="option.event"
      @click="handleClick(option.event)"
    >
      {{ option.title }}
    </div>
  </div>
</template>

<style scoped>
div.dropdown_menu_container {
  position: absolute;
  border: 1px #777777 solid;
  background-color: white;
  z-index: 1;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
}

div.dropdown_menu_item {
  padding: 8px;
  text-align: left;
  color: black;
  font-family: Fira Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

div.dropdown_menu_item:hover {
  background-color: #aaaaaa;
}
</style>

<script>
/* eslint-disable */
export default {
  name: "DropdownActionMenu",
  props: {
    options: Array,
    width: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleClick(eventName) {
      this.visible = false;
      this.$emit(eventName);
    },
    display() {
      this.visible = true;
    },
    onClickOutside() {
      this.visible = false;
    },
    /*handleClick(option) {
			this.selected = option;
			this.dropdownVisible = false;
			this.$emit('select', option[this.valueProperty]);
		},
		displayDropdown() {
			this.dropdownVisible = true;
		}*/
  },
};
</script>
