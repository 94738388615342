<template>
  <h1>{{ solutionName }}</h1>
  <table>
    <tr>
      <th>Material</th>
      <th>Parts</th>
      <th>Weight</th>
      <th>Energy CO₂</th>
      <th>Production CO₂</th>
      <th>Recovery CO₂</th>
    </tr>
    <tr v-for="(material, index) in packagingMaterials" :key="index">
      <td>{{ material.name }}</td>
      <td>{{ material.parts }}</td>
      <td>{{ material.weight }}</td>
      <td>{{ material.energy_co2 }}</td>
      <td>{{ material.production_co2 }}</td>
      <td>{{ material.recovery_co2 }}</td>
    </tr>
  </table>
  <p>Products per packaging: {{ solution.productsPerPackaging }}</p>
  <p>
    CO₂ equivalents total:
    {{ solution.getPartsCombinedTotalFormatted() }} CO₂/kg
  </p>
  <p>
    CO₂ equivalents per product:
    {{ solution.getCO2EqPerProductFormatted() }} CO₂/kg
  </p>
</template>

<style scoped>
table {
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: Fira Sans;
  font-weight: 400;
}

th {
  padding: 0px 10px;
}

td {
  padding: 0px 10px;
}

tr.header {
  background-color: var(--primary);
  height: 50px;
}

tr {
  height: 48px;
}

th {
  border-top: 1px solid var(--border);
  font-weight: 400;
}

table th:first-child {
  border-top-left-radius: 10px;
  border-left: 1px solid var(--border);
}

table th:last-child {
  border-top-right-radius: 10px;
  border-right: 1px solid var(--border);
}

table tr:nth-child(even) {
  background-color: var(--table-row-bg);
}

tr td:first-child {
  border-left: 1px solid var(--border);
}

tr td:last-child {
  border-right: 1px solid var(--border);
}

table tr:last-child td {
  border-bottom: 1px solid var(--border);
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
  border-left: 1px solid var(--border);
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
  border-right: 1px solid var(--border);
}
</style>

<script>
export default {
  name: "SalesSolution",
  computed: {
    solution() {
      return this.$store.getters["sales/getSelectedSolution"];
    },
    solutionName() {
      return this.solution.name;
    },
    packagingMaterials() {
      return this.solution.packagingMaterials;
    },
    productsPerPackaging() {
      return this.solution.productsPerPackaging;
    },
  },
};
</script>
