<template>
  <ActionButton
    v-if="!minimizeButton"
    @click="$emit('click')"
    :disabled="disabled"
    :icon="icon"
    :color="color"
    :text="label"
  />
  <IconButton
    v-if="minimizeButton"
    @click="$emit('click')"
    :disabled="disabled"
    :icon="icon"
    :color="color"
    :label="label"
  />
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import IconButton from "@/components/ui/IconButton.vue";

export default {
  name: "WidthScalingButton",
  emits: ["click"],
  props: {
    disabled: Boolean,
    cutoff: Number,
    icon: String,
    label: String,
    color: { type: String, default: "black" },
  },
  components: {
    ActionButton,
    IconButton,
  },
  computed: {
    minimizeButton() {
      var width = this.$store.getters["window/getWidth"];
      return width < this.cutoff;
    },
  },
};
</script>
