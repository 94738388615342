export const selectedSolutions = {
  namespaced: true,
  state: {
    solutions: [],
    bars: [],
  },
  actions: {
    setup({ commit }, data) {
      commit("setSolutions", data);
      let bars = [];
      data.forEach((solution) => {
        bars.push({
          name: solution.name,
          value: solution.partsCombinedTotal,
        });
      });
      let values = data.map((solution) => solution.partsCombinedTotal);
      let orderOfMagnitude = Math.floor(Math.log10(Math.max(...values)) + 0.5);
      if (isNaN(orderOfMagnitude) || !isFinite(orderOfMagnitude)) {
        orderOfMagnitude = 1;
      }
      let comparisonLabel =
        "1" + "0".repeat(orderOfMagnitude) + "€ worth of emissions¹";
      bars.push({
        name: comparisonLabel,
        value: +(1.408 * Math.pow(10, orderOfMagnitude)).toFixed(4),
      });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      bars.forEach((bar) => {
        context.font = "16px Avenir, Helvetica, Arial";
        bar.textWidth = context.measureText(bar.name).width;
      });
      canvas.remove();
      commit("setBars", bars);
    },
  },
  getters: {
    getSolutions(state) {
      return state.solutions;
    },
    getBars(state) {
      return state.bars;
    },
  },
  mutations: {
    setSolutions(state, solutions) {
      state.solutions = solutions;
    },
    setBars(state, bars) {
      state.bars = bars;
    },
  },
};
