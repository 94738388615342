<template>
  <div>
    <IconComponent
      icon="trash"
      @click="handleClick"
      style="margin-top: 20px; cursor: pointer"
      color="black"
    />
  </div>
</template>

<script>
import IconComponent from "@/components/common/IconComponent.vue";

export default {
  name: "DeleteButton",
  components: {
    IconComponent,
  },
  props: {
    disabled: Boolean,
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        this.$emit("clickWhileDisabled");
      } else {
        this.$emit("delete");
      }
    },
  },
};
</script>
