<template>
  <vue-final-modal z-index="4" :content-style="contentStyle">
    <h2>Signup</h2>
    <div class="modal-text-container">
      <SignupForm hideButton ref="signupForm" @submit="handleSubmit" />
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        color="primary"
        icon="xmark"
        hollow
      />
      <ActionButton
        @click="modalSignup"
        text="Sign up"
        icon="pencil"
        color="primary"
      />
    </div>
    <div v-if="!loggedIn" style="margin-top: 40px">
      <p>
        By registering to Huld Green Packaging, you agree to our privacy policy.
      </p>
      <p>
        Do you already have a profile?
        <a href="#" @click="modalLogin()">Log in</a>
      </p>
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import SignupForm from "@/components/forms/SignupForm.vue";

export default {
  name: "SignupModal",
  components: {
    ActionButton,
    SignupForm,
  },
  emits: ["userCreated", "login"],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    title() {
      return "Sign up";
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    contentStyle() {
      if (this.loggedIn) {
        return "max-width: 642px";
      } else {
        return "max-width: 577px";
      }
    },
  },
  methods: {
    displayModal() {
      this.$vfm.show("modal");
    },
    modalSignup() {
      this.$refs.signupForm.submitForm();
    },
    handleSubmit() {
      this.$vfm.hideAll();
      this.$emit("userCreated");
    },
    modalCancel() {
      this.$vfm.hideAll();
    },
    modalLogin() {
      this.$vfm.hideAll();
      this.$emit("login");
    },
  },
};
</script>
