import api from "./api";
const API_PATH = "/v1/users/";

import AuthStatus from "@/models/auth-status";

class UserService {
  createUser(data) {
    return api.post(API_PATH, data);
  }

  getMe() {
    return api.get(API_PATH + "me");
  }

  async getAuthStatus() {
    let loggedIn = false;
    let sysRole = null;
    let defaultRedirect = "solutionList";
    await api
      .get(API_PATH + "me")
      .then((response) => {
        if (response.data.username) {
          loggedIn = true;
        }
        sysRole = response.data.sys_role_name;
        if (sysRole == "Sales") {
          defaultRedirect = "sales";
        }
      })
      .catch((e) => {
        console.error(e);
      });
    return new AuthStatus({
      loggedIn: loggedIn,
      sysRole: sysRole,
      defaultRedirect: defaultRedirect,
    });
  }

  async isAuthenticated() {
    var result;
    await api
      .get(API_PATH + "me")
      .then(() => {
        result = true;
      })
      .catch((response) => {
        console.error(response);
        result = false;
      });
    return result;
  }

  requestPasswordReset(data) {
    return api.post(API_PATH + "reset-password", data);
  }

  getPasswordResetRequest(token) {
    return api.get(API_PATH + "reset-password", { params: { token: token } });
  }

  changePasswordWithResetToken(token, data) {
    return api.put(API_PATH + "reset-password", data, {
      params: { token: token },
    });
  }

  checkIfEmailIsTaken(email) {
    return api.get(API_PATH + "email/" + email);
  }

  checkIfUsernameIsTaken(username) {
    return api.get(API_PATH + "username/" + username);
  }

  changePassword(data) {
    return api.patch(API_PATH + "change-password", data);
  }

  checkPassword(data) {
    return api.post(API_PATH + "check-password", data);
  }

  deleteMe(password) {
    return api.delete(API_PATH, { headers: { password: password } });
  }
}

export default new UserService();
