<template>
  <div
    style="
      z-index: 3;
      width: 100%;
      height: 71px;
      left: 0;
      bottom: 0;
      padding: 0px;
      background-color: var(--brand-blue);
      position: fixed;
    "
  >
    <div style="display: flex; height: 100%; align-items: center">
      <div style="margin-left: 33px; margin-right: 0px">
        <img
          style="margin-top: 6px"
          width="75"
          src="../../assets/huld-logo-white.svg"
        />
      </div>
      <div
        style="
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="footer-link-container">
          Created by
          <a href="http://huld.io" target="_blank" rel="noopener noreferrer"
            >huld.io</a
          >
        </div>
        <div class="footer-link-container">
          <router-link :to="{ name: 'privacy-policy' }"
            >Read our privacy policy</router-link
          >
        </div>
        <div class="footer-link-container">Contact info: sales@huld.io</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.footer-link-container {
  margin: 0px 40px;
  color: white;
  font-size: 14px;
}

a {
  color: white !important;
}
</style>

<script>
export default {
  name: "FooterComponent",
};
</script>
