<template>
  <NavComponent
    @clickLogOut="handleLogOutClick"
    @clickDeleteUser="handleDeleteUserClick"
    @clickNewSolution="handleNewSolutionClick"
  />
  <ModalsContainer />
  <div style="position: relative; top: 72px; padding-bottom: 151px">
    <!-- Find out if the plant logo in calculator view is needed. If not, this can be a static part of the layout
    <TitleComponent v-if="!loading" />
    -->
    <SpinnerComponent v-if="loading" />
    <router-view :key="$route.name" v-show="!loading" />
  </div>
  <FooterComponent />
</template>

<style>
:root {
  --primary: #376aed;
  --primary-hover: #294bc5;
  --black: #4b4b4b;
  --error: #ca1739;
  --error-hover: #e70e0e;
  --helptexts: #777777;
  --border: #bcbcbc;
  --table-row-bg: #f0f0f0;
  --brand-blue: #0047f2;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans");

@font-face {
  font-family: "Bw Gradual";
  src: url("assets/fonts/BwGradual-Bold.eot");
  src: local("fonts/Bw Gradual Bold"), local("BwGradual-Bold"),
    url("assets/fonts/BwGradual-Bold.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/BwGradual-Bold.woff2") format("woff2"),
    url("assets/fonts/BwGradual-Bold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Fira Sans, Helvetica, Arial, sans-serif;
  padding: 0px !important;
  overflow-y: scroll !important;
}

p,
li {
  font-family: Fira Sans, Avenir, Helvetica, Arial, sans-serif;
}

ul li:not(:last-child) {
  margin-bottom: 15px;
}

#app {
  font-family: Fira Sans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-family: BW Gradual, Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-family: BW Gradual, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

nav {
  font-family: BW Gradual, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 0px 10px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-active {
  text-shadow: 0 0 12px rgba(255 255 255 / 100%);
  font-weight: bold;
}

input.hide_spin_button::-webkit-outer-spin-button,
input.hide_spin_button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].hide_spin_button {
  -moz-appearance: textfield;
}

div.openable {
  visibility: hidden;
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border-style: none;
  overflow: hidden;
  transition: visibility 0s, opacity 0.5s linear;
}

div.openableVisible {
  visibility: visible;
  opacity: 1;
  padding: 10px;
  margin: 10px 0px;
  border-style: solid;
  overflow: visible;
  height: auto;
}

div.vfm__content {
  border: 2px solid black;
  margin: auto;
  margin-top: 30px;
  padding: 50px;
  border-radius: 4px;
  background-color: white;
  max-width: 750px;
  box-shadow: 8px 8px 8px -4px rgb(0 0 0 / 30%);
}

fieldset {
  padding: 8px 4px 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--border);
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(0 0 0 / 60%);
  margin-bottom: 10px;
}

legend {
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
}

input {
  background-color: #ffffff;
  width: calc(100% - 12px);
  padding: 0px;
  border: none;
  outline: none;
  margin-left: 6px;
  margin-right: 6px;
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s,
    font-size 600000s 0s, font-family 600000s 0s;
}

input:-webkit-autofill::spelling-error {
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

input:-webkit-autofill::first-line {
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

input:autofill {
  font-family: BW Gradual, Avenir, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
}

.helptext {
  color: var(--helptexts);
}

.vue-treeselect__control {
  border: none !important;
  height: 20px !important;
}

.vue-treeselect__single-value,
.vue-treeselect__placeholder {
  line-height: normal !important;
}

.vue-treeselect__value-container {
  top: 0px !important;
}

table.results_table {
  font-family: Fira Sans, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

table.results_table > tr > td {
  padding: 10px;
}

div.modal-text-container {
  margin-top: 40px;
}

div.modal-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

div.form-fields-container {
  display: flex;
  flex-direction: column;
}

div.form-buttons-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import LoginModal from "@/components/modals/LoginModal.vue";
import LogOutModal from "@/components/modals/LogOutModal.vue";
import SignupModal from "@/components/modals/SignupModal.vue";
import DeleteUserModal from "@/components/modals/DeleteUserModal.vue";
import SpinnerComponent from "@/components/layout/SpinnerComponent.vue";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal.vue";

import AuthService from "@/services/auth.service";
import solutionService from "@/services/solution.service";

import { useToast } from "vue-toastification";
import { $vfm, ModalsContainer } from "vue-final-modal";

export default {
  components: {
    ModalsContainer,
    SpinnerComponent,
  },
  data() {
    return {
      showLogOutModal: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  created() {
    this.saveWindowSize();
    window.addEventListener("resize", this.resizeHandler);
    this.$store.dispatch("packagingMaterials/fetchMaterials");
    this.$store.dispatch("user/fetchCurrentUser");
    this.$store.dispatch("auth/fetchCurrentUser");
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    loading() {
      return this.$store.getters["loading/getLoading"];
    },
    unsavedChanges() {
      return this.$store.getters["solution/getUnsavedChanges"];
    },
  },
  watch: {
    loggedIn(status) {
      if (this.$route.meta.requiresAuth && !status) {
        this.$router.push({ name: "home" });
      }
    },
  },
  methods: {
    async modalLogout() {
      this.$vfm.hideAll();
      await AuthService.logout();
      this.$store.dispatch("user/resetUser");
      this.$router.push({ name: "home" });
    },
    modalCancel() {
      this.$vfm.hideAll();
    },
    handleLogOutClick() {
      const toast = this.toast;
      const router = this.$router;
      const store = this.$store;
      $vfm.show({
        component: LogOutModal,
        bind: {
          unsavedChanges: this.unsavedChanges,
        },
        on: {
          saveAndLogout() {
            let solution = store.getters["solution/getSolution"];
            let CSRFToken = store.getters["auth/getCSRFToken"];
            if (!solution.name) {
              solution.name = "Unnamed solution";
            }
            solutionService
              .saveSolution(solution.mapToSubmitRequest(), CSRFToken)
              .then(() => AuthService.logout())
              .then(() => {
                store.dispatch("user/resetUser");
                router.push({ name: "home" });
                toast.success("Saved and logged out");
              });
          },
          logout() {
            AuthService.logout().then(() => {
              store.dispatch("user/resetUser");
              router.push({ name: "home" });
              toast.success("Logged out");
            });
          },
        },
      });
    },
    handleDeleteUserClick() {
      const toast = this.toast;
      const router = this.$router;
      const store = this.$store;
      $vfm.show({
        component: DeleteUserModal,
        on: {
          userDeleted() {
            store.dispatch("user/resetUser");
            store.dispatch("auth/logout");
            router.push({
              name: "home",
            });
            toast.success("User deleted and logged out");
          },
        },
      });
    },
    handleNewSolutionClick() {
      const toast = this.toast;
      const router = this.$router;
      const store = this.$store;
      const loggedIn = this.loggedIn;
      const handleShowLoginAndSaveIfLogin = this.handleShowLoginAndSaveIfLogin;
      if (this.unsavedChanges) {
        $vfm.show({
          component: UnsavedChangesModal,
          on: {
            save() {
              if (loggedIn) {
                let solution = store.getters["solution/getSolution"];
                let CSRFToken = store.getters["auth/getCSRFToken"];
                if (!solution.name) {
                  solution.name = "Unnamed solution";
                }
                solutionService
                  .saveSolution(solution.mapToSubmitRequest(), CSRFToken)
                  .then(() => {
                    store.dispatch("solution/resetSolution");
                    router.push({ name: "solution" });
                    toast.success(
                      "Solution saved successfully and new solution created!"
                    );
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              } else {
                handleShowLoginAndSaveIfLogin();
              }
            },
            discard() {
              store.dispatch("solution/resetSolution");
              router.push({ name: "solution" });
              toast.success("New solution created!");
            },
          },
        });
      } else {
        this.handleNewSolutionDiscard();
      }
    },
    handleNewSolutionDiscard() {
      this.$store.dispatch("solution/resetSolution");
      this.$router.push({ name: "solution" });
      this.toast.success("New solution created!");
    },
    saveWindowSize() {
      this.$store.dispatch("window/saveSize", {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    resizeHandler() {
      this.saveWindowSize();
    },
    handleSaveSolution() {
      if (this.loggedIn) {
        this.saveSolution();
      } else {
        this.handleShowLoginAndSaveIfLogin();
      }
    },
    handleShowLoginAndSaveIfLogin() {
      const saveSolution = this.saveSolution;
      const showLoginAndSaveIfLogin = this.showLoginAndSaveIfLogin;
      $vfm.show({
        component: SignupModal,
        bind: {
          saveSolution: saveSolution,
          showLoginAndSaveIfLogin: showLoginAndSaveIfLogin,
        },
        on: {
          userCreated() {
            saveSolution();
          },
          login() {
            showLoginAndSaveIfLogin();
          },
        },
      });
    },
    showLoginAndSaveIfLogin() {
      const saveSolution = this.saveSolution;
      $vfm.show({
        component: LoginModal,
        bind: {
          saveSolution: saveSolution,
        },
        on: {
          login() {
            saveSolution();
          },
        },
      });
    },
    saveSolution(
      message = "Solution saved successfully!",
      keepSolution = true
    ) {
      let CSRFToken = this.$store.getters["auth/getCSRFToken"];
      let solution = this.$store.getters["solution/getSolution"];
      if (!solution.name) {
        solution.name = "Unnamed solution";
      }
      solutionService
        .saveSolution(solution.mapToSubmitRequest(), CSRFToken)
        .then(async (response) => {
          if (keepSolution) {
            this.$store.dispatch(
              "solution/setSolutionId",
              response.data.solution_id
            );
          } else {
            this.$store.dispatch("solution/resetSolution");
          }
          this.$store.dispatch("solution/markChangesAsSaved");
          await this.$store.dispatch("userSolutions/loadSolutions");
          this.toast.success(message);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
