<template>
  <div style="text-align: left">
    <h1>Huld's Privacy Statement</h1>
    <p>
      Privacy Statement according to the European Union General Data Protection
      Regulation (GDPR)
    </p>
    <p>Published: May 15, 2018</p>
    <p>Updated: March 20, 2023</p>
    <h2>1. Data Protection Principles in General</h2>
    <p>
      For Huld Oy, it is important to act responsibly and make sure that all
      personal data in our business is processed and protected in compliance
      with the required laws and regulations.
    </p>
    <h2>2. The Controller</h2>
    <p>Huld Oy, Keilasatama 5, FIN-02150 Espoo</p>
    <p>Persons responsible for the data filing system:</p>
    <ul>
      <li>Tuomas Martin, tuomas.martin@huld.io (customer files)</li>
      <li>
        Susanna Pirinen, susanna.pirinen@huld.io (files over prospective
        employees)
      </li>
    </ul>
    <h2>3. Contact Information in Data Protection Related Issues</h2>
    <p>
      Any questions and/or requests related to data protection can be directed
      to marketing@huld.io.
    </p>
    <h2>4. Legal Basis of the Processing of Personal Data</h2>
    <p>
      According to the EU’s General Data Protection Regulation (GDPR), the
      following qualify for legal basis for the processing of personal data:
    </p>
    <ul>
      <li>the (documented) consent of the data subject,</li>
      <li>performance of a contract,</li>
      <li>the controller’s legal obligation, or</li>
      <li>the protection of legitimate interests.</li>
    </ul>
    <h2>5. Data Collection and Purposes</h2>
    <p>
      We collect data for customer relations purposes as well as for marketing
      purposes. Data is collected either when a natural person registers as an
      user or when a natural sends a contact request.
    </p>
    <h3>5.1 Green Packaging</h3>
    <p style="font-weight: bold">What kind of data do we collect?</p>
    <p>
      When a natural person registers in Green Packaging, the following data is
      collected:
    </p>
    <ul>
      <li>E-mail address</li>
      <li>User name</li>
    </ul>
    <p style="font-weight: bold">How and when do we collect data?</p>
    <p>
      Any data mentioned above and stored in our Green Packaging database is
      provided by the user his- or herself.
    </p>
    <h3>5.2 The Duration of Personal Data Processing</h3>
    <p>
      Huld stores the data as long as we have a statutory right or obligation to
      do so. We go actively through our database and erase any unnecessary or
      outdated data.
    </p>
    <p>Data is not used for automated decision-making or profiling.</p>
    <h2>6. Cookie Policy</h2>
    <p>We do not collect any data for tracking and analytics purposes.</p>
    <h2>
      7. Regular Disclosures of Data, and Data Transfer to Countries Outside the
      EU or EEA
    </h2>
    <p>There are no regular disclosures of data to other parties.</p>
    <p>Your personal data is disclosed only to following parties:</p>
    <ul>
      <li>The police, for criminal investigation purposes.</li>
      <li>
        In projects that include marketing, to our associated partners who
        analyze, print, or distribute marketing material.
      </li>
    </ul>
    <p>
      We may disclose your personal data to our associate partners who act as
      data processors. These processors, that is, our associate partners that
      process personal data on our behalf, are bound by a contract with Huld. A
      contract ensures Huld has the right to oblige its associated partners to
      follow the European Union General Data Protection Regulation (GDPR). Our
      associated partners are not allowed to use the data in our filing system
      for any other purposes than those contracted with Huld for a specific
      order of trade.
    </p>
    <h2>8. Principles Applied to the Protection of the Filing System</h2>
    <p>
      The processing of personal data in our filing system is carried out with
      due care. Appropriate measures are taken to protect the data that is
      processed through information systems. In case personal data is stored on
      Internet servers, appropriate measures are taken to ensure the physical
      and digital data security of such equipment. Huld Oy ensures that the
      stored data, server user rights and other data that is critical for the
      security of personal data are processed in confidence and only by
      employees whose work duties require them to do so.
    </p>
    <h2>9. What Are Your Rights Related to the Processing of Personal Data?</h2>
    <h3>9.1. Right to Verify Data, and Right to Rectification</h3>
    <p>
      All data subjects have the right to verify their data stored in the filing
      system and obtain from the controller the rectification of any incorrect
      information or the completion of any incomplete personal data. If data
      subjects want to verify their personal data stored in the filing system,
      requests concerning such verification must be sent to in writing to
      marketing@huld.io. The data controller may require the requester to
      provide proof of identity if necessary.
    </p>
    <p>
      The data controller will respond to the customer within the time limit set
      out in the EU's General Data Protection Regulation (in general, within one
      month).
    </p>
    <h3>9.2. Right to Be Forgotten</h3>
    <p>
      Data subjects have the right to request from the controller the erasure of
      personal data concerning them (“the right to be forgotten”). Furthermore,
      data subjects have other rights, as set out in the EU’s General Data
      Protection Regulation, including the right to restriction of the
      processing of personal data in certain circumstances. Such requests must
      be sent to the data controller in writing. The data controller may require
      the requester to provide proof of identity if necessary.
    </p>
    <p>
      The controller responds to the data subject within the time limit
      specified in the European Union General Data Protection Regulation (in
      general, within one month).
    </p>
    <h3>9.3. Right to Data Portability</h3>
    <p>
      You have the right to receive your own personal data that you have
      provided to us in a structured, commonly used format so that you can
      transmit your data to another controller. Such requests must be directed
      to the address given in this Privacy Statement.
    </p>
    <h3>9.4. Right to Complaint</h3>
    <p>
      The data subject has the right to lodge a complaint with a supervisory
      authority. In Finland, the supervisory authority is The Office of the Data
      Protection Ombudsman.
    </p>
    <h2>10. Changes to This Privacy Statement</h2>
    <p>
      We reserve the right to make changes in this Privacy Statement. This
      statement will be updated whenever principles for processing of personal
      data, alternately the legislation applicable, are updated or changed.
    </p>
  </div>
</template>

<style scoped>
h2 {
  font-family: BW Gradual, Helvetica, Arial, sans-serif;
  font-size: 27px;
  font-weight: 700;
}

h3 {
  font-family: BW Gradual, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

ul li {
  margin-bottom: 5px;
}
</style>

<script>
export default {
  name: "SignUpView",
};
</script>
