<template>
  <HelpCard>
    <h1>How to use the editor</h1>
    <p>
      Click <em>Add new material</em> to add a new packaging material to your
      context. You may reorder your materials by dragging them.
    </p>
    <p>
      Choose your material from the <em>Material</em> dropdown menu. Fill in
      <em>Parts per package</em> to define how many parts of that material are
      used in the packaging, as well as <em>Part weight</em> to define how much
      one part of that material weighs.
    </p>
    <p>
      Fill in <em>Products per packaging</em> to define how many product units
      are included in one packaging unit.
    </p>
    <p>
      Click <em>Save</em> to save your changes. Click <em>Calculate</em> to save
      and display a final calculation of the carbon footprint of your solution.
    </p>
  </HelpCard>
</template>

<script>
import { StyledCard } from "@/components/styled";

const HelpCard = StyledCard.extend`
  background-color: white;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 80px;
  width: 600px;
  box-shadow: 4px 4px 8px #888888;
  margin-left: auto;
  margin-right: auto;
`;

export default {
  name: "EditorHelp",
  emits: ["closeHelp"],
  components: {
    HelpCard,
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.$emit("closeHelp");
      }
    });
  },
};
</script>
