<template>
  <StyledCard v-if="token" style="margin-top: 80px">
    <h1>Reset password</h1>
    <ResetPasswordForm :token="token" @submit="handleSubmit" />
  </StyledCard>
</template>

<script>
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";
import { StyledCard } from "@/components/styled";

import UserService from "@/services/user.service";

import { useToast } from "vue-toastification";

export default {
  name: "ResetPasswordView",
  components: {
    ResetPasswordForm,
    StyledCard,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      token: null,
    };
  },
  created() {
    if (this.$route.params.tokenId) {
      localStorage.setItem("urlToken", this.$route.params.tokenId);
      window.location.replace(window.location.origin + "/reset-password");
    } else {
      this.token = localStorage.getItem("urlToken");
      localStorage.removeItem("urlToken");
      if (this.token) {
        UserService.getPasswordResetRequest(this.token).catch((e) => {
          if (e.response.status == 400) {
            this.toast.error("No valid password reset request found.");
          } else if (e.response.status == 429) {
            this.toast.error(
              "Too many requests in a short time. Try again after a short while."
            );
          } else {
            this.toast.error(
              "An error occurred while trying to access your password reset request."
            );
          }
          this.$router.push({ name: "home" });
        });
      } else {
        this.$router.push({ name: "home" });
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
