import SolutionService from "@/services/solution.service";
import Solution from "@/models/solution";

export const userSolutions = {
  namespaced: true,
  state: {
    solutions: [],
    /*chartData: {
      labels: [],
      datasets: [],
    },*/
  },
  actions: {
    async loadSolutions({ commit }) {
      return new Promise((resolve, reject) => {
        SolutionService.getMySolutions()
          .then((response) => {
            let data = [];
            let selectedComponents = 0;
            if (response.status === 200) {
              response.data.forEach((solutionData) => {
                let solution = new Solution(solutionData);
                if (solution.checkStatus()) {
                  if (selectedComponents < 10) {
                    solution.selectedForComparison = true;
                    selectedComponents++;
                  }
                }
                solution.calculate();
                data.push(solution);
              });
            }
            commit("setSolutions", data);
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject();
          });
      });
    },
    saveSolutions({ commit }, payload) {
      commit("setSolutions", payload);
    },
    /*setChartData({ commit }, payload) {
      if (payload) {
        let labels = payload.map((solution) => {
          return solution.selectedForComparison ? solution.name : solution.name;
        });
        let datasets = [
          {
            label: "",
            backgroundColor: payload.map((solution) => {
              return solution.selectedForComparison ? "#123456" : "#123456";
            }),
            data: payload.map((solution) => {
              return solution.selectedForComparison
                ? solution.partsCombinedTotal
                : null;
            }),
            skipNull: true,
          },
        ];
        let orderOfMagnitude = Math.floor(
          Math.log10(Math.max(datasets[0].data)) + 0.5
        );
        if (isNaN(orderOfMagnitude) || !isFinite(orderOfMagnitude)) {
          orderOfMagnitude = 1;
        }
        let comparisonLabel =
          "1" + "0".repeat(orderOfMagnitude) + "€ worth of emissions¹";
        let comparisonValue = +(1.408 * Math.pow(10, orderOfMagnitude)).toFixed(
          4
        );
        labels.push(comparisonLabel);
        datasets[0].backgroundColor.push("#e4e4e4");
        datasets[0].data.push(comparisonValue);
        let chartData = {
          labels: labels,
          datasets: datasets,
        };
        commit("saveChartData", chartData);
      }
    },*/
  },
  getters: {
    getSolutions(state) {
      return state.solutions;
    },
    getSelectedSolutions(state) {
      if (state.solutions) {
        return state.solutions.filter(
          (solution) => solution.selectedForComparison
        );
      }
      return [];
    },
    getChartData(state) {
      return state.chartData;
    },
    hasSolutions(state) {
      return state.solutions && state.solutions.length > 0;
    },
  },
  mutations: {
    setSolutions(state, solutions) {
      state.solutions = solutions;
    },
    /*saveChartData(state, chartData) {
      state.chartData = chartData;
    },*/
  },
};
