<template>
  <LoginForm @submit="handleLogin" :width="width" />
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import UserService from "@/services/user.service";
import { useToast } from "vue-toastification";

export default {
  name: "LoginComponent",
  components: {
    LoginForm,
  },
  props: {
    width: {
      type: String,
      default: "300px",
    },
  },
  emits: ["login"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: "solution" });
    }
  },
  methods: {
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleLogin() {
      UserService.getAuthStatus().then((response) => {
        let sysRole = response.sysRole;
        if (sysRole == "Sales") {
          this.$router.push({ name: "sales" });
        } else {
          this.$router.push({ name: "solutionList" });
        }
      });
    },
  },
};
</script>
