<template>
  <div style="margin-left: auto; margin-right: auto; background-color: #fff">
    <fieldset :style="fieldStyle">
      <legend>{{ label }}</legend>
      <Field
        :style="fieldStyle"
        :value="modelValue"
        :name="name"
        :type="type"
        @input="handleInput"
        ref="field"
        :key="componentKey"
        :placeholder="placeholder"
      />
    </fieldset>
  </div>
</template>

<script>
/* eslint-disable */
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "SingleRowField",
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: String,
    name: String,
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    labelWidthFull: {
      type: String,
      default: "100px",
    },
    labelWidthMin: {
      type: String,
      default: "50px",
    },
    width: {
      type: String,
      default: "300px",
    },
    minimizeCutoff: {
      type: Number,
      default: 500,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      hasFocus: true,
      componentKey: 0,
    };
  },
  /*mounted() {
    setTimeout(() => {
      this.$refs.field.handleChange();
    }, 1000);
  },*/
  computed: {
    windowWidth() {
      return this.$store.getters["window/getWidth"];
    },
    isMinimized() {
      return this.windowWidth < this.minimizeCutoff;
    },
    labelWidth() {
      if (this.isMinimized) {
        return this.labelWidthMin;
      }
      return this.labelWidthFull;
    },
    fieldStyle() {
      //return "width: 294px; padding: 0px; border: none; outline: none; margin-left: 6px; font-size: 16px;";
      //return "background-color: #fff; width: 300px; padding: 0px; border: none; outline: none; margin-left: 6px; margin-right: 6px; font-size: 16px;";
      //return "width: " + this.fieldWidth;
      return "width: " + this.width + ";";
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    handlePaste(e) {
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");
      text = text.replace(/\r?\n|\r/g, "");
      document.execCommand("insertText", false, text);
      this.$emit("update:modelValue", e.target.innerText);
    },
    handleFocus() {
      //this.hasFocus = true;
    },
    handleFocusOut() {
      //this.hasFocus = false;
    },
  },
};
</script>
