<template>
  <div>
    <div style="margin-bottom: 80px">
      <img src="../assets/green-packaging.svg" alt="Green Packaging by Huld" />
      <h2 style="margin-top: 14px">
        We will make it easy for you to calculate, compare and understand your
        packaging CO2 footprint
      </h2>
    </div>
    <div style="margin-bottom: 48px">
      <ActionButton @click="startSolution" icon="star" text="Quick start" />
      <p class="helptext">
        You can quickly check how the calculator works. You don't need to sign
        up yet.
      </p>
    </div>
    <div style="margin-bottom: 48px">
      <ActionButton
        @click="$router.push('signup')"
        icon="signup"
        text="Sign up"
      />
      <p class="helptext">
        Create an account to save several calculations and to compare them.
      </p>
    </div>
    <p class="helptext">Already have an account? Just log in!</p>
    <LoginComponent width="490px" />
  </div>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import LoginComponent from "@/components/common/LoginComponent.vue";
export default {
  name: "HomeView",
  components: {
    ActionButton,
    LoginComponent,
  },
  created() {
    this.$store.dispatch("solution/setAvailableMaterials");
  },
  computed: {
    showHomePage() {
      return !this.$store.getters["auth/loggedIn"];
    },
  },
  methods: {
    startSolution() {
      this.$store.dispatch("solution/resetSolution");
      this.$router.push({ name: "solution" });
    },
  },
};
</script>
