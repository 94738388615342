const initialState = {
  loading: false,
};

export const loading = {
  namespaced: true,
  state: initialState,
  actions: {
    setLoading({ commit }, payload) {
      commit("saveLoading", payload);
    },
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    saveLoading(state, data) {
      state.loading = data;
    },
  },
};
