/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/";
import { store } from "./store";
import { defineRule } from "vee-validate";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { ThemeProvider } from "vue3-styled-components";
import NavComponent from "@/components/layout/NavComponent";
import FooterComponent from "@/components/layout/FooterComponent";
import TitleComponent from "@/components/layout/TitleComponent";
import HuldLogo from "@/components/common/HuldLogo";
//import setupInterceptors from "@/services/setupInterceptors";
import { vfmPlugin } from "vue-final-modal";
import vClickOutside from "click-outside-vue3";

defineRule("required", (value) => {
  if (!value || !value.length) {
    return "This field is required";
  }

  return true;
});

//setupInterceptors();
const app = createApp(App);
app.component("theme-provider", ThemeProvider);
app.component("NavComponent", NavComponent);
app.component("FooterComponent", FooterComponent);
app.component("TitleComponent", TitleComponent);
app.component("HuldLogo", HuldLogo);
app.use(store);
app.use(router);
app.use(Toast);
app.use(vfmPlugin);
app.use(vClickOutside);
app.mount("#app");
