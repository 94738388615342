import AuthService from "@/services/auth.service";
import UserService from "@/services/user.service";

const initialState = {
  status: {
    loggedIn: false,
  },
  CSRFToken: null,
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async fetchAuth({ commit }) {
      var data = {
        loggedIn: false,
      };
      UserService.getMe()
        .then((response) => {
          if (response.data.username) {
            data.loggedIn = true;
          }
        })
        .catch(() => {})
        .finally(() => {
          commit("setStatus", data);
        });
    },
    async fetchCurrentUser({ commit }) {
      UserService.getMe()
        .then((response) => {
          if (response.data.username) {
            commit("login");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    login({ commit }) {
      commit("login");
    },
    logout({ commit }) {
      commit("logout");
    },
    refreshToken({ commit }, accessToken) {
      commit("refreshToken", accessToken);
    },
    loadCSRFToken({ commit }) {
      AuthService.getCSRFToken()
        .then((response) => {
          commit("setCSRFToken", response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  getters: {
    loggedIn(state) {
      return state.status.loggedIn;
    },
    getCSRFToken(state) {
      return state.CSRFToken;
    },
  },
  mutations: {
    setStatus(state, data) {
      state.status = data;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    login(state) {
      state.status.loggedIn = true;
    },
    /*registerSuccess(state) {
			state.status.loggedIn = false;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},*/
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    setCSRFToken(state, CSRFToken) {
      state.CSRFToken = CSRFToken;
    },
  },
};
