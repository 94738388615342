import api from "./api";
const API_PATH = "/v1/sales/";

class SalesService {
  getAllUsers() {
    return api.get(API_PATH);
  }

  getAllSolutionsForUser(user_id) {
    return api.get(API_PATH + "users/" + user_id + "/solutions");
  }

  getAllSolutionsWithoutUser() {
    return api.get(API_PATH + "no-user/solutions");
  }

  closeRequestForSolution(solution_id) {
    return api.put(API_PATH + "solutions/" + solution_id + "/close");
  }
}

export default new SalesService();
