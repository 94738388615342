<template>
  <fieldset
    style="border-radius: 4px; border: 1px solid #cccccc; margin-top: 40px"
  >
    <h1>Disclaimer</h1>
    <p>
      The Huld Green Packaging CO₂e combines data collected from ELCD database
      and Y-hiilari calculator. The calculations are based on the material
      weights used in a packaging concept, taking into account the raw material
      production, material processing as energy and material recovery. The large
      variety requires paring the variables down to ones common between the
      chosen materials, representing the ones most used in packaging. The
      results should be considered as concept-level percentual difference
      between different packaging solutions to weigh in on choosing which one to
      develop further. The material choice is one factor among many when design
      sustainable packaging and logistics chains both raw materials and finished
      products should also be taken into consideration.
    </p>
  </fieldset>
</template>

<style scoped>
fieldset {
  padding: 10px 60px;
  text-align: left;
}
</style>
<script>
export default {
  name: "SourceDisclaimer",
};
</script>
