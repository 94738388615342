import User from "@/models/user";
import UserService from "@/services/user.service";

export const user = {
  namespaced: true,
  state: new User(),
  actions: {
    async fetchCurrentUser({ commit, getters }) {
      UserService.getMe()
        .then((response) => {
          var data;
          if (response.data.username) {
            data = getters.get;
            data.username = response.data.username;
            data.email = response.data.email;
            data.sys_role = response.data.sys_role_name;
          } else {
            data = new User();
          }
          commit("update", data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    resetUser({ commit, getters }) {
      var data = getters.get;
      data.username = null;
      data.email = null;
      data.sys_role = null;
      data.user_id = null;
      commit("update", data);
    },
  },
  getters: {
    get(state) {
      return state;
    },
    getUsername(state) {
      return state.username;
    },
    getEmail(state) {
      return state.email;
    },
  },
  mutations: {
    update(state, data) {
      state = data;
    },
  },
};
