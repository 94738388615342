<template>
  <StyledButton
    :disabled="disabled"
    :color="buttonColor"
    :colorHover="buttonColorHover"
    :hollow="hollow"
    @click="handleClick"
  >
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      "
    >
      <div v-if="icon" style="margin-right: 0.5em; margin-top: 3px">
        <IconComponent :icon="icon" :color="iconColor" style="height: 18px" />
      </div>
      <div>{{ text }}</div>
    </div>
  </StyledButton>
</template>

<script>
import { StyledButton } from "@/components/styled";
import IconComponent from "@/components/common/IconComponent.vue";

export default {
  name: "ActionButton",
  emits: ["click"],
  //props: ['icon', 'text', 'disabled'],
  props: {
    icon: { type: String, default: null },
    text: String,
    disabled: Boolean,
    hollow: Boolean,
    color: { type: String, default: "primary" },
  },
  components: {
    StyledButton,
    IconComponent,
  },
  computed: {
    buttonColor() {
      return "var(--" + this.color + ")";
    },
    buttonColorHover() {
      return "var(--" + this.color + "-hover) ";
    },
    iconColor() {
      if (this.hollow) {
        return this.color;
      } else {
        return "white";
      }
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>
