import Solution from "@/models/solution";
import SolutionPackagingMaterial from "@/models/solution-packaging-material";
import SolutionService from "@/services/solution.service";
import PackagingMaterialService from "@/services/packaging-material.service";
//import { EventBus } from "@/common/EventBus.js";

export const solution = {
  namespaced: true,
  state: {
    available_materials: [],
    solution: new Solution(),
    unsaved_changes: false,
  },
  actions: {
    loadSolution({ commit }, solutionId) {
      SolutionService.getSolution(solutionId)
        .then((response) => {
          let solution = new Solution(response.data);
          commit("setSolution", solution);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    resetSolution({ commit, rootGetters }) {
      let solution = new Solution();
      solution.solutionId = "";
      solution.productsPerPackaging = 1;
      commit("setSolution", solution);
      //var newMaterial = getters.getFirstAvailableMaterial;
      let materials = rootGetters["packagingMaterials/getMaterials"];
      let newMaterial = new SolutionPackagingMaterial(materials[0]);
      newMaterial.parts = 1;
      newMaterial.weight = 0.1;
      commit("add", newMaterial);
      commit("setUnsavedChanges", false);
    },
    addMaterial({ commit, getters, dispatch }) {
      var newMaterial = getters.getFirstAvailableMaterial;
      commit("add", newMaterial);
      dispatch("setAvailableMaterials");
      newMaterial.order = getters.getMaterials.length;
      return Promise.resolve(newMaterial);
    },
    changeMaterial({ commit, getters, dispatch }, payload) {
      var materials = getters.getMaterials;
      var oldMaterial = materials[payload.index];
      PackagingMaterialService.getPackagingMaterialById(payload.material_id)
        .then((response) => {
          var newMaterial = new SolutionPackagingMaterial(response);
          newMaterial.weight = oldMaterial.weight;
          newMaterial.parts = oldMaterial.parts;
          materials[payload.index] = newMaterial;
          commit("updateMaterials", materials);
          dispatch("setAvailableMaterials");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    deleteMaterial({ commit, getters, dispatch }, index) {
      var materials = getters.getMaterials;
      materials.splice(index, 1);
      commit("updateMaterials", materials);
      dispatch("setAvailableMaterials");
    },
    setMaterials({ commit, getters }, materials) {
      commit("updateMaterials", materials);
      var filterArray = getters.getMaterialIds;
      PackagingMaterialService.getPackagingMaterialsFiltered(filterArray)
        .then((response) => {
          commit("updateAvailableMaterials", response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    setAvailableMaterials({ commit, getters }) {
      var filterArray = getters.getMaterialIds;
      PackagingMaterialService.getPackagingMaterialsFiltered(filterArray)
        .then((response) => {
          commit("updateAvailableMaterials", response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    setParts({ commit, getters }, payload) {
      var materials = getters.getMaterials;
      materials[payload.index].parts = payload.parts;
      commit("updateMaterials", materials);
    },
    setWeight({ commit, getters }, payload) {
      var materials = getters.getMaterials;
      materials[payload.index].weight = payload.weight;
      commit("updateMaterials", materials);
    },
    setSolutionId({ commit, getters }, solutionId) {
      var solution = getters.getSolution;
      solution.solutionId = solutionId;
      commit("setSolution", solution);
    },
    markChangesAsSaved({ commit }) {
      commit("setUnsavedChanges", false);
    },
  },
  getters: {
    get(state) {
      return state;
    },
    getSolution(state) {
      return state.solution;
    },
    getName(state) {
      return state.solution.name;
    },
    getSolutionID(state) {
      return state.solution.solutionId;
    },
    getProducts(state) {
      return state.solution.productsPerPackaging;
    },
    getMaterials(state) {
      return state.solution.packagingMaterials;
    },
    getMaterialIds(state, getters) {
      var materials = getters.getMaterials;
      var ids = materials.map((e) => {
        return e.packaging_material_id;
      });
      return ids;
    },
    getAvailableMaterials(state) {
      return state.available_materials;
    },
    getFirstAvailableMaterial(state) {
      return new SolutionPackagingMaterial(state.available_materials[0]);
    },
    getPartsCombined(state) {
      return state.solution.getPartsCombinedTotal();
    },
    getCO2ToTotalPartsWeight(state) {
      return state.solution.getCO2ToTotalPartsWeight();
    },
    getCO2EqPerProduct(state) {
      return state.solution.getCO2EqPerProduct();
    },
    getStatus(state) {
      if (state.solution.packagingMaterials.length == 0) {
        return {
          isCalculable: false,
          message: "Your solution has no packaging materials!",
        };
      }
      var allMaterialsHaveParts = state.solution.packagingMaterials.every(
        (material) => {
          if (!material.parts) {
            return false;
          }
          return true;
        }
      );
      if (!allMaterialsHaveParts) {
        return {
          isCalculable: false,
          message:
            "All materials must have an amount of parts greater than zero!",
        };
      }

      var allMaterialsHaveWeight = state.solution.packagingMaterials.every(
        (material) => {
          if (!parseFloat(material.weight)) {
            return false;
          }
          return true;
        }
      );
      if (!allMaterialsHaveWeight) {
        return {
          isCalculable: false,
          message: "All materials must have a weight greater than zero!",
        };
      }

      if (!state.solution.productsPerPackaging) {
        return {
          isCalculable: false,
          message: "Products per packaging must be greater than zero!",
        };
      }
      return {
        isCalculable: true,
        message: "OK",
      };
    },
    getUnsavedChanges(state) {
      return state.unsaved_changes;
    },
  },
  mutations: {
    setSolution(state, solution) {
      state.solution = solution;
      state.unsaved_changes = false;
    },
    add(state, material) {
      state.solution.packagingMaterials.push(material);
      state.unsaved_changes = true;
    },
    updateMaterials(state, materials) {
      state.solution.packagingMaterials = materials;
      state.unsaved_changes = true;
    },
    updateAvailableMaterials(state, availableMaterials) {
      state.available_materials = availableMaterials;
    },
    setName(state, value) {
      state.solution.name = value;
      state.unsaved_changes = true;
    },
    setProducts(state, value) {
      state.solution.productsPerPackaging = value;
      state.unsaved_changes = true;
    },
    setUnsavedChanges(state, value) {
      state.unsaved_changes = value;
    },
  },
};
