<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div style="display: flex; flex-direction: column; margin-bottom: 40px">
        <SingleRowField
          label="New password"
          v-model="new_password"
          name="new_password"
          type="password"
          width="450px"
        />
        <SingleRowField
          label="Confirm password"
          v-model="confirm_password"
          name="confirm_password"
          type="password"
          width="450px"
        />
      </div>
      <ActionButton text="Change password" icon="login" v-if="!hideButton" />
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import UserService from "@/services/user.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

export default {
  name: "ChangePasswordForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
    currentPassword: String,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      new_password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
      confirm_password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .oneOf([yup.ref("new_password"), null], "Passwords must match"),
    });
    return {
      schema,
      new_password: "",
      confirm_password: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      let payload = values;
      payload.current_password = this.currentPassword;
      UserService.changePassword(payload)
        .then(() => {
          this.toast.success("Password changed!");
          this.$emit("submit");
        })
        .catch((error) => {
          this.toast.error(error.response.data);
        });
    },
  },
};
</script>
