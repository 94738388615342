<template>
  <fieldset
    style="
      border-radius: 4px;
      border: 1px solid #cccccc;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 30px;
    "
  >
    <h1>How to improve my packaging CO2?</h1>
    <p>
      You can reduce amount of packaging material by changing the packaging’s
      design. You can explore options with this calculator.
    </p>
    <ul>
      <li>
        Create a duplicate of your solution and change material weight to
        compare how big difference it could produce.
      </li>
      <li>
        You can switch to lower impact packaging material. Create a duplicate,
        change the material to something else and compare the difference.
      </li>
    </ul>
    <p>
      Feel free to ask free consultation from Huld to evaluate your potential to
      decrease the packaging CO2 and to optimize packaging.
    </p>
    <div style="text-align: center">
      <ActionButton
        text="Send consultation request"
        hollow
        width="324px"
        @click="handleConsultationRequestSubmit(this.email)"
      />
    </div>
  </fieldset>
</template>

<style scoped>
fieldset {
  padding: 10px 60px;
  text-align: left;
}
</style>
<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import { useToast } from "vue-toastification";
import solutionService from "@/services/solution.service";

export default {
  name: "HowToImprove",
  components: {
    ActionButton,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    email() {
      return this.$store.getters["user/getEmail"];
    },
  },
  methods: {
    handleConsultationRequestSubmit(email) {
      let CSRFToken = this.$store.getters["auth/getCSRFToken"];
      solutionService
        .addContactRequestToAllSolutions(email, CSRFToken)
        .then(() => {
          this.toast.info("Consultation request sent!");
        })
        .catch((error) => {
          this.toast.error(error.response.data.detail);
        });
    },
  },
};
</script>
