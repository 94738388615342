<template>
  <vue-final-modal
    z-index="4"
    v-bind="$attrs"
    content-style="max-width: 450px;"
  >
    <h2>Delete solution</h2>
    <div class="modal-text-container">
      <p>Are you sure you want to delete {{ $attrs.deletingSolution.name }}?</p>
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        color="primary"
        icon="xmark"
        hollow
      />
      <ActionButton
        @click="modalDelete"
        text="Delete solution"
        color="error"
        icon="trash"
      />
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";

export default {
  name: "DeleteSolutionModal",
  components: {
    ActionButton,
  },
  data() {
    return {
      params: null,
    };
  },
  emits: ["delete", "cancel"],
  methods: {
    modalDelete() {
      this.$emit("delete");
      this.$vfm.hideAll();
    },
    modalCancel() {
      this.$emit("cancel");
      this.$vfm.hideAll();
    },
  },
};
</script>
