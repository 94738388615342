export default class SolutionPackagingMaterial {
  constructor(args) {
    this.packaging_material_id = args.packaging_material_id;
    this.name = args.name;
    this.description = args.description;
    this.production_co2 = args.production_co2;
    this.energy_co2 = args.energy_co2;
    this.recovery_co2 = args.recovery_co2;
    this.production_source = args.production_source;
    this.energy_source = args.energy_source;
    this.recovery_source = args.recovery_source;
    this.weight = args.weight ? args.weight : null;
    this.parts = args.parts ? args.parts : null;
  }

  getProductionCO2() {
    return this.weight * this.production_co2;
  }

  getEnergyCO2() {
    return this.weight * this.energy_co2;
  }

  getRecoveryCO2() {
    return this.weight * this.recovery_co2;
  }

  getTotalCO2() {
    return (
      this.getProductionCO2() + this.getEnergyCO2() + this.getRecoveryCO2()
    );
  }
}
