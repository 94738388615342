import api from "./api";
import { store } from "@/store/index";
import qs from "qs";
const API_PATH = "/v1/login/";

class AuthService {
  async login(user) {
    var promise;
    await api
      .post(API_PATH + "token", qs.stringify(user), {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        store.dispatch("auth/login");
        promise = Promise.resolve(response);
      })
      .catch((e) => {
        promise = Promise.reject(e.response);
      });
    return promise;
  }

  async logout() {
    await api.post(API_PATH + "logout").then(() => {
      store.dispatch("auth/logout");
      store.dispatch("solution/resetSolution");
    });
  }

  getCSRFToken() {
    return api.get(API_PATH + "csrf");
  }
}

export default new AuthService();
