<template>
  <vue-final-modal z-index="4">
    <h2>Delete user</h2>
    <div class="modal-text-container">
      <DeleteUserForm hideButton ref="deleteUserForm" @submit="handleSubmit" />
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        color="primary"
        icon="xmark"
        hollow
      />
      <ActionButton
        @click="modalDelete"
        text="Delete"
        icon="xmark"
        color="primary"
      />
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import DeleteUserForm from "@/components/forms/DeleteUserForm.vue";

export default {
  name: "DeleteUserModal",
  components: {
    ActionButton,
    DeleteUserForm,
  },
  emits: ["userDeleted"],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    displayModal() {
      this.$vfm.show("modal");
    },
    modalDelete() {
      this.$refs.deleteUserForm.submitForm();
    },
    handleSubmit() {
      this.$vfm.hideAll();
      this.$emit("userDeleted");
    },
    modalCancel() {
      this.$vfm.hideAll();
    },
  },
};
</script>
