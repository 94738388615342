import { createRouter, createWebHistory } from "vue-router";
import ChangePasswordView from "@/views/ChangePasswordView.vue";
import SolutionListView from "@/views/SolutionListView";
import SolutionView from "@/views/SolutionView";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import ResetPasswordView from "@/views/ResetPasswordView";
import SalesView from "@/views/SalesView";
import SignUpView from "@/views/SignUpView";

import userService from "@/services/user.service";

import { store } from "@/store";

const DEFAULT_TITLE = "Green Packaging";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { redirectIfAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { redirectIfAuth: true, title: "Log in" },
  },
  {
    path: "/solution",
    name: "solution",
    component: SolutionView,
    meta: { title: "Calculator", newSolution: true },
  },
  {
    path: "/solution/:solutionId",
    name: "loadSolution",
    component: SolutionView,
    meta: { title: "Calculator", requiresAuth: true, newSolution: false },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUpView,
    meta: { title: "Sign up" },
  },
  {
    path: "/solution-list",
    name: "solutionList",
    component: SolutionListView,
    meta: {
      title: "My packaging solutions",
      requiresAuth: true,
      requiresSolutions: true,
    },
  },
  {
    path: "/reset-password/:tokenId",
    name: "reset-password-with-url-token",
    component: ResetPasswordView,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: ChangePasswordView,
    meta: { requiresAuth: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView,
    meta: { title: "Privacy policy" },
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
    meta: { title: "Sales", requiresAuth: true, requiresSales: true },
  },
  {
    path: "/huld-website",
    name: "huld-website",
    beforeEnter() {
      location.href = "http://www.huld.io";
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  const loadingTimeout = setTimeout(() => {
    store.dispatch("loading/setLoading", true);
  }, 500);
  userService.getAuthStatus().then(async (response) => {
    clearTimeout(loadingTimeout);
    store.dispatch("loading/setLoading", false);
    if (response.loggedIn) {
      await store.dispatch("userSolutions/loadSolutions");
    }
    if (to.meta.redirectIfAuth && response.loggedIn) {
      next({ name: response.defaultRedirect });
    } else if (to.meta.requiresAuth && !response.loggedIn)
      next({ name: "login" });
    else if (!to.meta.requiresSales && response.sysRole == "Sales")
      next({ name: "sales" });
    else if (to.meta.requiresSales && response.sysRole != "Sales")
      next({ name: "solutionList" });
    else if (to.meta.requiresSolutions) {
      if (!response.loggedIn) {
        next({ name: "login " });
      } else {
        var hasSolutions = store.getters["userSolutions/hasSolutions"];
        if (!hasSolutions) {
          next({ name: "solution" });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  });
});

// eslint-disable-next-line
router.afterEach((to, from) => {
  var title = DEFAULT_TITLE;
  if (to.meta.title) {
    title += " | " + to.meta.title;
  }
  document.title = title;
});

export default router;
