<template>
  <vue-final-modal z-index="4">
    <h2>You have unsaved changes!</h2>
    <div class="modal-text-container">
      <p>Are you sure you want to discard your changes?</p>
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        icon="xmark"
        color="primary"
        hollow
      />
      <ActionButton
        @click="modalDiscard"
        text="Discard changes"
        color="error"
        icon="trash"
      />
      <ActionButton
        @click="modalSave"
        text="Save changes"
        icon="floppy"
        color="primary"
      />
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";

export default {
  name: "UnsavedChangesModal",
  components: {
    ActionButton,
  },
  data() {
    return {
      params: null,
    };
  },
  emits: ["save", "discard", "cancel"],
  methods: {
    modalSave() {
      this.$emit("save");
      this.$vfm.hideAll();
    },
    modalDiscard() {
      this.$emit("discard");
      this.$vfm.hideAll();
    },
    modalCancel() {
      this.$emit("cancel");
      this.$vfm.hideAll();
    },
  },
};
</script>
