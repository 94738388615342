<template>
  <vue-final-modal z-index="4">
    <h2>Close contact request</h2>
    <div class="modal-text-container">
      <p>
        Are you sure you want to close the contact request for
        {{ $attrs.closingSolution.name }}?
      </p>
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        icon="xmark"
        color="primary"
        hollow
      />
      <ActionButton
        @click="modalClose"
        text="Close request"
        color="error"
        icon="trash"
      />
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";

export default {
  name: "CloseSolutionRequestModal",
  components: {
    ActionButton,
  },
  data() {
    return {
      params: null,
    };
  },
  emits: ["close", "cancel"],
  methods: {
    modalClose() {
      this.$emit("close");
      this.$vfm.hideAll();
    },
    modalCancel() {
      this.$emit("cancel");
      this.$vfm.hideAll();
    },
  },
};
</script>
