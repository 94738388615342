<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <div style="display: flex; flex-direction: column; margin: 32px 0px">
        <SingleRowField
          label="E-mail"
          v-model="email"
          name="email"
          width="784px"
          style="background: rgba(0, 0, 0, 0)"
        />
      </div>
      <ActionButton
        text="Send consultation request"
        v-if="!hideButton"
        hollow
        width="324px"
      />
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import SolutionService from "@/services/solution.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import * as yup from "yup";

export default {
  name: "RequestConsultationForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    const store = useStore();
    return { toast, store };
  },
  data() {
    const schema = yup.object({
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be a valid email"),
    });
    return {
      schema,
      email: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      this.$emit("submit", values.email);
    },
  },
};
</script>
