<template>
  <div style="margin-right: 80px; white-space: nowrap">
    <p>
      <router-link :to="to">{{ title }}</router-link>
    </p>
  </div>
</template>

<style scoped>
a {
  color: white;
  text-decoration: none;
}
</style>

<script>
/* eslint-disable */
import styled from "vue3-styled-components";

const StyledDiv = styled("div", { isActive: Boolean })`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  transition-duration: 0.5s;
  color: ${(props) => (props.isActive ? "black" : "#b3b3b3")};
  filter: ${(props) =>
    props.isActive ? "drop-shadow(0px 0px 2px #0007)" : "none"};
  &:hover {
    color: black;
  }
`;

const FullStyledDiv = styled("div", { isActive: Boolean })`
  font-size: 15px;
  margin-top: 7px;
  margin-bottom: auto;
  cursor: pointer;
  transition-duration: 0.5s;
  color: ${(props) => (props.isActive ? "black" : "#666666")};
  filter: ${(props) =>
    props.isActive ? "drop-shadow(0px 0px 2px #000d)" : "none"};
  &:hover {
    color: black;
    filter: ${(props) =>
      props.isActive
        ? "drop-shadow(0px 0px 2px #000d)"
        : "drop-shadow(0px 0px 1px #0007)"};
  }
`;

const IconDiv = StyledDiv.extend`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#aaa" : "#ddd")};
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #bbb;
  }
`;

export default {
  name: "NavLink",
  emits: ["click"],
  props: {
    to: String,
    text1: String,
    text2: String,
    icon: String,
    asButton: Boolean,
    asIcon: Boolean,
    iconTitle: String,
    title: String,
    name: String,
  },
  components: {
    StyledDiv,
    IconDiv,
    FullStyledDiv,
  },
  data() {
    return {
      minimizeCutoff: 530,
    };
  },
  computed: {
    windowWidth() {
      return this.$store.getters["window/getWidth"];
    },
    isMinimized() {
      return this.windowWidth < this.minimizeCutoff;
    },
    to() {
      return { name: this.name };
    },
  },
};
</script>
