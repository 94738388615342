<template>
  <StyledButton
    style="height: 38px"
    :disabled="disabled"
    :color="color"
    :title="title"
  >
    <IconComponent :icon="icon" />
  </StyledButton>
</template>

<script>
import IconComponent from "@/components/common/IconComponent.vue";
import { StyledButton } from "@/components/styled";

export default {
  name: "IconButton",
  components: {
    StyledButton,
    IconComponent,
  },
  props: {
    disabled: Boolean,
    color: {
      type: String,
      default: "black",
    },
    icon: String,
    title: String,
  },
};
</script>
