export const window = {
    namespaced: true,
    state: {
        width: 0,
        height: 0
    },
    actions: {
        saveSize({ commit }, payload) {
            commit('setSize', payload);
        }
    },
    getters: {
        getWidth(state) {
            return state.width;
        }
    },
    mutations: {
        setSize(state, size) {
            state.width = size.width;
            state.height = size.height;
        }
    }
}