<template>
  <PlantSymbol />
  <h1 style="text-align: left">My packaging solutions</h1>
  <p style="text-align: left">You can edit, duplicate and delete solutions.</p>
  <SolutionListSection @logout="handleLogout" v-if="hasSolutions" />
  <div v-if="!hasSolutions">
    <p class="helptext">There are no saved solutions yet.</p>
  </div>
  <div
    style="
      width: 100%;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    "
  >
    <ActionButton
      icon="plus"
      text="Create new solution calculation"
      @click="createSolution"
      style="width: 460px"
    />
  </div>
  <HowToImprove />
  <SourceDisclaimer />
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";
import PlantSymbol from "@/components/common/PlantSymbol.vue";
import SolutionListSection from "@/views/SolutionListView/SolutionListSection.vue";
import SourceDisclaimer from "@/components/common/SourceDisclaimer.vue";
import HowToImprove from "@/components/common/HowToImprove.vue";

export default {
  name: "SolutionListView",
  components: {
    ActionButton,
    PlantSymbol,
    SolutionListSection,
    SourceDisclaimer,
    HowToImprove,
  },
  created() {
    this.$store.dispatch("auth/loadCSRFToken");
    this.$store.dispatch("userSolutions/loadSolutions");
    this.$store.dispatch("solution/setAvailableMaterials");
  },
  computed: {
    hasSolutions() {
      return this.$store.getters["userSolutions/hasSolutions"];
    },
  },
  methods: {
    handleLogout() {
      this.$router.push({ name: "home" });
    },
    createSolution() {
      this.$router.push({ name: "solution" });
    },
  },
};
</script>
