<template>
  <LoginComponent @login="handleLogin" style="padding-top: 80px" />
</template>

<script>
import LoginComponent from "@/components/common/LoginComponent.vue";

export default {
  name: "LoginView",
  components: {
    LoginComponent,
  },
  methods: {
    handleLogin() {
      this.$router.push({ name: "solutionList" });
    },
  },
};
</script>
