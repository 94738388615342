import PackagingMaterialService from "@/services/packaging-material.service";

export const packagingMaterials = {
  namespaced: true,
  state: {
    materials: null,
    options: null,
  },
  actions: {
    async fetchMaterials({ commit }) {
      PackagingMaterialService.getAllPackagingMaterials().then(
        (response) => {
          commit("setMaterials", response.data);
        },
        (error) => {
          this.solution =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    async generateOptions({ commit }) {
      PackagingMaterialService.getAllClasses().then(
        (response) => {
          let options = [];
          let newOption = {};
          Object.values(response.data).forEach((materialClass) => {
            //options.options.push(materialClass);
            newOption = {
              id: "c" + materialClass.packaging_material_class_id,
              label: materialClass.name,
              children: [],
            };
            Object.values(materialClass.packaging_materials).forEach(
              (material) => {
                newOption.children.push({
                  id: material.packaging_material_id,
                  label: material.name,
                });
              }
            );
            options.push(newOption);
          });
          commit("setOptions", options);
        },
        (error) => {
          this.solution =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  getters: {
    getMaterials(state) {
      return state.materials;
    },
    getOptions(state) {
      return state.options;
    },
  },
  mutations: {
    setMaterials(state, materials) {
      state.materials = materials;
    },
    setOptions(state, options) {
      state.options = options;
    },
  },
};
