<template>
  <fieldset
    style="
      border-radius: 4px;
      padding: 20px;
      background-color: #e3dbd0;
      margin-top: 48px;
      margin-bottom: 48px;
    "
  >
    <div
      v-if="!solutionStatus.isCalculable"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 200px;
      "
    >
      <h1>{{ solutionStatus.message }}</h1>
    </div>
    <div
      v-if="solutionStatus.isCalculable"
      style="
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 200px;
        max-width: 808px;
      "
    >
      <div>
        <h1 style="overflow-wrap: break-word">
          CO₂ footprint for {{ solutionName }}
        </h1>
        <table class="results_table">
          <tr>
            <td>For one package:</td>
            <td style="text-align: right">{{ partsCombined }} CO₂/kg</td>
          </tr>
          <tr>
            <td>For one product:</td>
            <td style="text-align: right">{{ co2EqPerProduct }} CO₂/kg</td>
          </tr>
        </table>
      </div>
    </div>
  </fieldset>
</template>

<script>
import SolutionService from "@/services/solution.service";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  name: "ResultsComponent",
  setup() {
    const router = useRouter();
    const toast = useToast();
    return { router, toast };
  },
  beforeCreate() {
    var solutionStatus = this.$store.getters["solution/getStatus"];
    if (!solutionStatus.isCalculable) {
      this.$router.push({ name: "solution" });
    }
  },
  computed: {
    solutionName() {
      let name = this.$store.getters["solution/getName"];
      if (name && name.length > 0) {
        return name;
      }
      return "this solution";
    },
    solutionStatus() {
      return this.$store.getters["solution/getStatus"];
    },
    partsCombined() {
      var n = this.$store.getters["solution/getPartsCombined"];
      return Number(n.toFixed(4));
    },
    co2ToTotalPartsWeight() {
      var n =
        100 *
        parseFloat(this.$store.getters["solution/getCO2ToTotalPartsWeight"]);
      return Number(n.toFixed(0));
    },
    co2EqPerProduct() {
      var n = this.$store.getters["solution/getCO2EqPerProduct"];
      return Number(n.toFixed(4));
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    solutionIsSaved() {
      var solutionId = this.$store.getters["solution/getSolutionID"];
      if (solutionId) {
        return true;
      }
      return false;
    },
  },
  methods: {
    saveSolution() {
      var solution = this.$store.getters["solution/getSolution"];
      var data = solution.mapToSubmitRequest();
      SolutionService.postSolution(data)
        .then((response) => {
          var solutionId = response.data.solution_id;
          this.$store.dispatch("solution/setSolutionId", solutionId);
          this.toast.success("Solution saved!");
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
