<template>
  <StyledCard style="margin-top: 80px">
    <h1>Forgot password?</h1>
    <ForgotPasswordForm @submit="handleSubmit" />
  </StyledCard>
</template>

<script>
import ForgotPasswordForm from "@/components/forms/ForgotPasswordForm.vue";

import { StyledCard } from "@/components/styled";

export default {
  name: "ForgotPasswordView",
  components: {
    ForgotPasswordForm,
    StyledCard,
  },
  methods: {
    handleSubmit() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
