<template>
  <PlantSymbol />
  <h1 style="text-align: left">Calculator</h1>
  <h2 style="text-align: left">What is a packaging solution?</h2>
  <p style="text-align: left">
    One packaging solution can consist of several parts that are made of several
    materials. Additionally, one package may include several products. With this
    calculator you can calculate CO2 footprints for different packaging
    solutions. Calculator provides the CO₂ footprints per one package and one
    product.
  </p>
  <SolutionEditor />
</template>

<script>
import PlantSymbol from "@/components/common/PlantSymbol.vue";
import SolutionEditor from "@/views/SolutionView/SolutionEditor.vue";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal.vue";

import solutionService from "@/services/solution.service";

import { $vfm } from "vue-final-modal";
import { onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export default {
  name: "SolutionView",
  components: {
    SolutionEditor,
    PlantSymbol,
  },
  data() {
    return {
      to: null,
    };
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    // eslint-disable-next-line no-unused-vars
    onBeforeRouteLeave(async (to, from) => {
      let unsavedChanges = store.getters["solution/getUnsavedChanges"];
      if (!unsavedChanges) {
        return true;
      }

      let resolvePromise = null;
      const promise = new Promise((resolve) => (resolvePromise = resolve));

      $vfm.show({
        component: UnsavedChangesModal,
        on: {
          save() {
            let CSRFToken = store.getters["auth/getCSRFToken"];
            let solution = store.getters["solution/getSolution"];
            if (!solution.name) {
              solution.name = "Unnamed solution";
            }
            solutionService
              .saveSolution(solution.mapToSubmitRequest(), CSRFToken)
              .then(() => {
                store.dispatch("solution/markChangesAsSaved");
                toast.success("Solution saved successfully!");
                resolvePromise(true);
              })
              .catch((e) => {
                console.error(e);
              });
          },
          discard() {
            resolvePromise(true);
          },
          cancel() {
            resolvePromise(false);
          },
        },
      });

      const result = await promise.then((value) => {
        return value;
      });

      return result;
    });
  },
  created() {
    if (this.$route.meta.newSolution) {
      this.$store.dispatch("solution/resetSolution");
    }
    if (this.$route.params.solutionId) {
      this.$store.dispatch(
        "solution/loadSolution",
        this.$route.params.solutionId
      );
    }
    this.$store.dispatch("solution/setAvailableMaterials");
  },
  computed: {
    unsavedChanges() {
      return this.$store.getters["solution/getUnsavedChanges"];
    },
  },
  methods: {
    saveSolution(message = "Solution saved successfully!") {
      let CSRFToken = this.$store.getters["auth/getCSRFToken"];
      if (!this.solutionName) {
        this.solutionName = "Unnamed solution";
      }
      solutionService
        .saveSolution(this.solution.solution.mapToSubmitRequest(), CSRFToken)
        .then((response) => {
          this.$store.dispatch(
            "solution/setSolutionId",
            response.data.solution_id
          );
          this.$store.dispatch("solution/markChangesAsSaved");
          this.toast.success(message);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
