import { createStore } from "vuex";
import { auth } from "./auth.module";
import { solution } from "./solution.module";
import { loading } from "./loading.module";
import { userSolutions } from "./user-solutions.module";
import { packagingMaterials } from "./packaging-materials.module";
import { selectedSolutions } from "./selected-solutions.module";
import { user } from "./user.module";
import { window } from "./window.module";
import { sales } from "./sales.module";

export const store = createStore({
  modules: {
    auth,
    solution,
    loading,
    userSolutions,
    packagingMaterials,
    selectedSolutions,
    user,
    window,
    sales,
  },
});
