<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div style="display: flex; flex-direction: column">
        <SingleRowField
          label="Password"
          v-model="password"
          name="password"
          type="password"
        />
      </div>
      <ActionButton text="Delete user" icon="xmark" v-if="!hideButton" />
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import UserService from "@/services/user.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

export default {
  name: "DeleteUserForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      password: yup.string().required("Password is required"),
    });
    return {
      schema,
      password: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      UserService.deleteMe(values.password)
        .then(() => {
          this.$emit("submit");
        })
        .catch((e) => {
          this.toast.error(e.response.data);
        });
    },
  },
};
</script>
