<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div style="display: flex; flex-direction: column">
        <SingleRowField label="Username" v-model="username" name="username" />
        <SingleRowField label="E-mail" v-model="email" name="email" />
        <SingleRowField
          label="Password"
          v-model="password"
          name="password"
          type="password"
        />
      </div>
      <ActionButton text="Sign up" icon="signup" v-if="!hideButton" />
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import User from "@/models/user";

import authService from "@/services/auth.service";
import UserService from "@/services/user.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

let usernameDelayTimerObject = { timer: null };
let emailDelayTimerObject = { timer: null };

const setValidationTimeout = async (value, timerObject, callback) => {
  clearTimeout(timerObject.timer);
  return await new Promise((resolve) => {
    timerObject.timer = setTimeout(async () => {
      if (!value) {
        // If there is no value, resolve to true. An empty username or email cannot be taken.
        // Of course, an empty username or email will fail validation anyway.
        resolve(true);
      } else {
        callback(value)
          .then((response) => {
            resolve(response.data.detail);
          })
          .catch((e) => {
            console.error(e);
            resolve(true);
          });
      }
    }, 500);
  });
};

export default {
  name: "SignUpForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      username: yup
        .string()
        .required("Username is required")
        .min(3, "Username must be at least 3 characters")
        .test("Unique Username", "Username already in use", async (value) => {
          const result = await setValidationTimeout(
            value,
            usernameDelayTimerObject,
            UserService.checkIfUsernameIsTaken
          );
          return result;
        }),
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be a valid email")
        .test("Unique Email", "Email already in use", async (value) => {
          const result = await setValidationTimeout(
            value,
            emailDelayTimerObject,
            UserService.checkIfEmailIsTaken
          );
          return result;
        }),
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
    });
    return {
      schema,
      username: "",
      email: "",
      password: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleSubmit(values) {
      /*var data = {
        ...values,
      };*/
      UserService.createUser(values)
        .then(() => {
          var user = new User();
          user.username = values.username;
          user.password = values.password;
          return authService.login(user);
        })
        .then(() => {
          this.$store.dispatch("user/fetchCurrentUser");
        })
        .then(() => {
          this.$emit("submit");
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
