<template>
  <div style="margin-top: 125px; text-align: right">
    <img src="../../assets/huld_logo_blue.png" style="width: 130px" />
  </div>
</template>

<script>
export default {
  name: "HuldLogo",
};
</script>
