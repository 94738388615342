<template>
  <StyledDiv @click="$router.push({ name: 'home' })">
    <img src="../../assets/green-packaging.svg" />
  </StyledDiv>
</template>

<script>
import styled from "vue3-styled-components";

const StyledDiv = styled("div", { isActive: Boolean })`
  cursor: pointer;
`;

export default {
  name: "PlantSymbol",
  components: {
    StyledDiv,
  },
};
</script>
