<template>
  <fieldset
    style="
      border-radius: 4px;
      border: 1px solid #dddddd;
      margin-top: 40px;
      margin-bottom: 0px;
    "
  >
    <h2 style="text-align: left; margin-left: 15px; margin-bottom: 0px">
      My packaging solutions
    </h2>
    <p style="text-align: left; margin-left: 15px; margin-top: 5px">
      Carbon footprint comparison
    </p>
    <hr />
    <div :style="chartStyle">
      <Bar
        :v-if="chartData"
        :data="chartData"
        :options="chartOptions"
        :plugins="chartPlugins"
        ref="myBar"
      />
    </div>
    <p style="text-align: left; margin-left: 230px; margin-right: 170px">
      ¹Comparison value based EEX's price for the emissions allowances in the
      EU's Emissions Trading System (71 eur/t CO₂)
    </p>
  </fieldset>
</template>

<script>
/* eslint-disable */
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "SolutionComparison",
  components: { Bar },
  /*props: {
    solutions: Array,
  },*/
  data() {
    const chartOptions = {
      indexAxis: "y",
      scales: {
        y: {
          ticks: {
            font: {
              size: 16,
              family: "Fira Sans",
            },
            major: {
              enabled: true,
              fontStyle: "italic",
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      /*transitions: {
        show: {
          animations: {
            x: {
              from: 0,
            },
            y: null,
          },
        },
        hide: {
          animations: {
            x: {
              to: 0,
            },
            y: null,
          },
        },
      },*/
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      showInlineValues: true,
    };
    const chartPlugins = [
      {
        beforeDatasetsDraw: function (chart, args, options) {
          let box = chart.boxes.find((box) => box.axis == "y");
          if (box._labelItems) {
            const [lastTick] = box._labelItems.slice(-1);
            if (lastTick && lastTick.font) {
              lastTick.font.string = "italic 16px Fira Sans";
            }
          }
        },
      },
    ];
    return {
      chartOptions,
      chartPlugins,
    };
  },
  created() {
    let solutions = this.$store.getters["userSolutions/getSolutions"];
  },
  computed: {
    chartStyle() {
      let height = 0;
      if (this.solutions) {
        let selectedSolutions = this.solutions.filter(
          (solution) => solution.selectedForComparison
        );
        height = 70 * (selectedSolutions.length + 1);
      }
      return (
        "margin-left: auto; margin-right: auto; width: 800px; height: " +
        height.toString() +
        "px;"
      );
    },
    solutions() {
      return this.$store.getters["userSolutions/getSolutions"];
    },
    chartData() {
      const palette = [
        "#1F54A5",
        "#AC9D86",
        "#FE90DF",
        "#3761C7",
        "#C6B69F",
        "#FD2CC3",
        "#80A4FF",
        "#D0C9BF",
        "#FE90DF",
        "#C2D1FA",
      ];
      let labels = [];
      let backgroundColor = [];
      let data = [];
      if (this.solutions) {
        let selectedSolutions = this.solutions.filter(
          (solution) => solution.selectedForComparison
        );
        labels = selectedSolutions.map((solution) => solution.name);
        /*backgroundColor = selectedSolutions.map((solution, i) => {
          return i % 2 == 0 ? "#FE90DF" : "#C2D1FA";
        });*/
        backgroundColor = selectedSolutions.map((solution, i) => {
          return palette[i];
        });
        data = selectedSolutions.map((solution) => {
          return solution.selectedForComparison
            ? solution.partsCombinedTotal
            : null;
        });
        let orderOfMagnitude = Math.floor(Math.log10(Math.max(...data)) + 0.5);
        if (isNaN(orderOfMagnitude) || !isFinite(orderOfMagnitude)) {
          orderOfMagnitude = 1;
        }
        let comparisonLabel =
          "1" + "0".repeat(orderOfMagnitude) + "€ worth of emissions¹";
        let comparisonValue = +(1.408 * Math.pow(10, orderOfMagnitude)).toFixed(
          4
        );
        labels.push(comparisonLabel);
        backgroundColor.push("#e4e4e4");
        data.push(comparisonValue);
      }
      let datasets = [
        {
          label: "",
          backgroundColor: backgroundColor,
          categoryPercentage: [0.2, 0.4, 0.6, 0.2, 0.4, 0.6],
          data: data,
        },
      ];
      let chartData = {
        labels: labels,
        datasets: datasets,
      };
      return chartData;
    },
  },
};
</script>
