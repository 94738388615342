<template>
  <SolutionEditorInstructions />
  <div style="margin-bottom: 48px">
    <h2 style="text-align: left">
      How would you like to name your packaging solution?
    </h2>
    <fieldset style="max-height: 55px">
      <legend>Solution name</legend>
      <input id="parts" type="text" v-model="solutionName" maxlength="50" />
    </fieldset>
  </div>

  <div style="margin-bottom: 48px">
    <h2 style="text-align: left">
      What parts the packaging solution consists of?
    </h2>
    <fieldset style="border-radius: 4px; padding: 20px">
      <template
        v-for="(material, index) in solutionMaterials"
        :key="material.packaging_material_id"
      >
        <SolutionPackagingMaterial
          :material="material"
          :index="index"
          @enterWeight="updateWeight"
        />
      </template>
      <fieldset style="width: 325px; max-height: 55px">
        <legend>Number of products per packaging solution</legend>
        <input
          id="products"
          type="text"
          :value="solutionProducts"
          @input="handleProductsInput"
        />
      </fieldset>
      <ActionButton
        icon="plus"
        text="Add new packaging part"
        style="width: 400px; margin-top: 25px"
        @click="addPackagingMaterial"
        hollow
      />
    </fieldset>
    <ActionButton
      :disabled="!unsavedChanges"
      text="Save packaging solution"
      @click="$root.handleSaveSolution"
      style="width: 400px; margin-top: 48px"
    />
  </div>
  <ResultsComponent />
  <div>
    <ActionButton
      icon="plus"
      text="Create new packaging solution"
      style="width: 400px; margin-top: 25px"
      @click="$root.handleNewSolutionClick"
      hollow
    />
  </div>
  <div>
    <ActionButton
      text="Compare your existing solutions"
      style="width: 400px; margin-top: 25px"
      :disabled="!loggedIn"
      @click="$router.push({ name: 'solutionList' })"
    />
  </div>
  <div style="margin-bottom: 20px; margin-top: 48px">
    <h2 style="text-align: left">
      Do you have problems filling out the questionnaire?
    </h2>
    <fieldset
      style="background-color: #f2f2f2; margin-top: 30px"
      v-if="!loggedIn"
    >
      <RequestConsultationForm
        style="margin-bottom: 15px"
        @submit="handleConsultationRequestSubmit"
      />
    </fieldset>
    <div v-if="loggedIn">
      <ActionButton
        text="Send consultation request"
        hollow
        width="324px"
        @click="handleConsultationRequestSubmit(this.email)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { StyledCard, StyledTextInput } from "@/components/styled";
import ActionButton from "@/components/ui/ActionButton.vue";
import SolutionEditorInstructions from "@/views/SolutionView/SolutionEditorInstructions.vue";
import SolutionPackagingMaterial from "@/views/SolutionView/SolutionPackagingMaterial.vue";
import EditorHelp from "@/views/SolutionView/EditorHelp.vue";
import ResultsComponent from "@/views/SolutionView/ResultsComponent.vue";
import RequestConsultationForm from "@/components/forms/RequestConsultationForm.vue";
import SignupModal from "@/components/modals/SignupModal.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal.vue";
import WidthScalingButton from "@/components/ui/WidthScalingButton.vue";
import styled from "vue3-styled-components";
import solutionService from "@/services/solution.service";

import { $vfm } from "vue-final-modal";
import { useToast } from "vue-toastification";
import { Form } from "vee-validate";
import * as yup from "yup";

const SolutionNameInput = styled.input`
  font-size: 22px;
  text-align: center;
  padding: 5px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  display: block;
`;

export default {
  name: "SolutionEditor",
  components: {
    ActionButton,
    SolutionEditorInstructions,
    SolutionNameInput,
    SolutionPackagingMaterial,
    EditorHelp,
    Form,
    ResultsComponent,
    RequestConsultationForm,
    SingleRowField,
    StyledCard,
    StyledTextInput,
    WidthScalingButton,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      email: yup
        .string()
        .required("Email is required")
        .email("Email must be a valid email"),
    });
    return {
      schema,
      displayHelp: false,
    };
  },
  async created() {
    this.$store.dispatch("auth/loadCSRFToken");
    if (this.$store.getters["packagingMaterials/getMaterials"] === null) {
      await this.$store.dispatch("packagingMaterials/fetchMaterials");
    }
    if (this.$store.getters["packagingMaterials/getOptions"] === null) {
      await this.$store.dispatch("packagingMaterials/generateOptions");
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
    email() {
      return this.$store.getters["user/getEmail"];
    },
    solution() {
      return this.$store.getters["solution/get"];
    },
    unsavedChanges() {
      return this.$store.getters["solution/getUnsavedChanges"];
    },
    solutionId() {
      return this.$store.getters["solution/getSolutionID"];
    },
    productsLabel() {
      var width = this.$store.getters["window/getWidth"];
      if (width < 370) {
        return "Products";
      }
      return "Products per packaging";
    },
    solutionName: {
      get() {
        return this.$store.getters["solution/getName"];
      },
      set(value) {
        this.$store.commit("solution/setName", value);
      },
    },
    solutionProducts: {
      get() {
        return this.$store.getters["solution/getProducts"];
      },
      set(value) {
        this.$store.commit("solution/setProducts", parseInt(value) || "");
      },
    },
    solutionMaterials: {
      get() {
        return this.$store.getters["solution/getMaterials"];
      },
      set(materials) {
        this.$store.dispatch("solution/setMaterials", materials);
      },
    },
    isCalculable() {
      var status = this.$store.getters["solution/getStatus"];
      return status.isCalculable;
    },
    message() {
      var status = this.$store.getters["solution/getStatus"];
      return status.message;
    },
  },
  methods: {
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    handleRequest() {
      this.toast.success("Request sent!");
    },
    handleNewSolutionClick() {
      if (this.unsavedChanges) {
        this.$vfm.show("newSolution");
      } else {
        this.$store.dispatch("solution/resetSolution");
      }
    },
    displaySignupModal() {
      const unsavedChanges = this.unsavedChanges;
      const toast = this.toast;
      const router = this.$router;
      const store = this.$store;
      $vfm.show({
        component: SignupModal,
        on: {
          userCreated() {
            if (unsavedChanges) {
              let solution = store.getters["solution/getSolution"];
              let CSRFToken = store.getters["auth/getCSRFToken"];
              if (!solution.name) {
                solution.name = "Unnamed solution";
              }
              solutionService
                .saveSolution(solution.mapToSubmitRequest(), CSRFToken)
                .then(() => {
                  store.dispatch("solution/markChangesAsSaved");
                  toast.success(
                    "User created and solution saved successfully!"
                  );
                  router.push({ name: "solutionList" });
                })
                .catch((e) => {
                  console.error(e);
                });
            } else {
              toast.success("User created successfully!");
              router.push({ name: "solutionList" });
            }
          },
        },
      });
    },
    toggleHelp() {
      this.displayHelp = !this.displayHelp;
    },
    closeHelp() {
      this.displayHelp = false;
    },
    addPackagingMaterial() {
      this.$store.dispatch("solution/addMaterial");
    },
    saveSolution(message = "Solution saved successfully!") {
      let CSRFToken = this.$store.getters["auth/getCSRFToken"];
      if (!this.solutionName) {
        this.solutionName = "Unnamed solution";
      }
      solutionService
        .saveSolution(this.solution.solution.mapToSubmitRequest(), CSRFToken)
        .then((response) => {
          this.$store.dispatch(
            "solution/setSolutionId",
            response.data.solution_id
          );
          this.$store.dispatch("solution/markChangesAsSaved");
          this.toast.success(message);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    goToMyData() {
      if (this.unsavedChanges) {
        let CSRFToken = this.$store.getters["auth/getCSRFToken"];
        if (!this.solutionName) {
          this.solutionName = "Unnamed solution";
        }
        solutionService
          .saveSolution(this.solution.solution.mapToSubmitRequest(), CSRFToken)
          .then(() => {
            this.$store.dispatch("solution/markChangesAsSaved");
            this.toast.success("Solution saved successfully!");
            this.$router.push({ name: "solutionList" });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        this.$router.push({ name: "solutionList" });
      }
    },
    modalSave() {
      this.saveSolution(
        "Solution saved successfully and new solution created!"
      );
      this.$store.dispatch("solution/resetSolution");
      this.$vfm.hideAll();
    },
    modalDiscard() {
      this.$store.dispatch("solution/resetSolution");
      this.toast.success("New solution created!");
      this.$vfm.hideAll();
    },
    modalCancel() {
      this.$vfm.hideAll();
    },
    handleProductsInput(e) {
      var value = e.target.value;
      value = value.replace(/\D/g, "");
      e.target.value = value;
      this.solutionProducts = value;
    },
    handleConsultationRequestSubmit(email) {
      let solution = this.$store.getters["solution/getSolution"];
      if (solution.solutionId) {
        let CSRFToken = this.$store.getters["auth/getCSRFToken"];
        solutionService
          .addContactRequestToSolution(solution.solutionId, email, CSRFToken)
          .then(() => {
            this.toast.info("Consultation request sent!");
          })
          .catch((error) => {
            this.toast.error(error.response.data.detail);
          });
      } else {
        solutionService
          .postSolutionWithContactRequest(solution.mapToSubmitRequest(), email)
          .then(() => {
            this.toast.info("Consultation request sent!");
          })
          .catch((error) => {
            this.toast.error(error.response.data.detail);
          });
      }
    },
  },
};
</script>
