<style>
.vue-treeselect__control {
  height: 40px;
  border-width: 1px;
  border-color: #000;
  border-radius: 4px;
}

.vue-treeselect__value-container {
  top: 2px;
}
</style>

<template>
  <div style="margin-left: auto; margin-right: auto; margin-bottom: 15px">
    <div
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <fieldset style="width: 400px; max-height: 55px">
        <legend>Part material</legend>
        <treeselect
          v-model="selectedMaterialId"
          :options="options"
          :disable-branch-nodes="true"
        />
      </fieldset>
      <fieldset style="width: 175px; max-height: 55px">
        <legend>Part weight (kg)</legend>
        <input
          id="weight"
          type="text"
          :value="this.materialWeight"
          @input="handleWeightInput"
        />
      </fieldset>
      <fieldset style="width: 150px; max-height: 55px">
        <legend>Parts per package</legend>
        <input
          id="parts"
          type="text"
          :value="this.materialParts"
          @input="handlePartsInput"
        />
      </fieldset>
      <DeleteButton @delete="deleteMaterial" style="height: 38px" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import SingleRowField from "@/components/ui/SingleRowField.vue";
import { StyledTextInput } from "@/components/styled";
import DeleteButton from "@/components/ui/DeleteButton.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

export default {
  props: ["material", "index"],
  components: {
    StyledTextInput,
    SingleRowField,
    DeleteButton,
    Treeselect,
  },
  data() {
    return {
      selectedMaterialId: this.material.packaging_material_id,
      content: this.material,
      materials: null,
    };
  },
  async created() {
    if (this.$store.getters["packagingMaterials/getMaterials"] === null) {
      await this.$store.dispatch("packagingMaterials/fetchMaterials");
    }
    if (this.$store.getters["packagingMaterials/getOptions"] === null) {
      await this.$store.dispatch("packagingMaterials/generateOptions");
    }
  },
  computed: {
    solutionId() {
      return this.$store.getters["solution/getSolutionID"];
    },
    smallMode() {
      var width = this.$store.getters["window/getWidth"];
      return width < 600;
    },
    selectedMaterial() {
      return this.materials[this.selectedMaterialId - 1];
    },
    options() {
      return this.$store.getters["packagingMaterials/getOptions"];
    },
    materialParts: {
      get() {
        var materials = this.$store.getters["solution/getMaterials"];
        return materials[this.index].parts;
      },
      set(parts) {
        this.$store.dispatch("solution/setParts", {
          index: this.index,
          parts: parseInt(parts) || "",
        });
      },
    },
    materialWeight: {
      get() {
        var materials = this.$store.getters["solution/getMaterials"];
        return materials[this.index].weight;
      },
      set(weight) {
        this.$store.dispatch("solution/setWeight", {
          index: this.index,
          weight: weight,
        });
      },
    },
  },
  watch: {
    selectedMaterialId() {
      this.setMaterial();
    },
  },
  methods: {
    handleEnter() {
      this.$emit("enterWeight", this.content);
    },
    setMaterial() {
      if (this.selectedMaterialId) {
        this.$store.dispatch("solution/changeMaterial", {
          index: this.index,
          material_id: this.selectedMaterialId,
        });
      }
    },
    deleteMaterial() {
      this.$store.dispatch("solution/deleteMaterial", this.index);
    },
    handleWeightInput(e) {
      let value = e.target.value;
      value = value.replace(",", ".").replace(/[^\d.]/g, "");
      var split = value.split(".");
      if (split.length > 1) {
        value = split.shift() + "." + split.join("");
      }
      e.target.value = value;
      this.materialWeight = value;
    },
    handlePartsInput(e) {
      var value = e.target.value;
      value = value.replace(/\D/g, "");
      e.target.value = value;
      this.materialParts = value;
    },
  },
};
</script>
