<template>
  <div>
    <Form
      @submit="handleSubmit"
      @invalid-submit="handleInvalidSubmit"
      :validation-schema="schema"
      ref="form"
    >
      <div class="form-fields-container">
        <SingleRowField
          label="Username or email"
          v-model="username"
          name="username"
          style="margin-bottom: 14px"
          :width="width"
        />
        <SingleRowField
          label="Password"
          v-model="password"
          name="password"
          type="password"
          style="margin-bottom: 14px"
          :width="width"
        />
      </div>
      <div style="margin-top: 10px; margin-bottom: 30px">
        <router-link style="color: #376aed" to="/forgot-password"
          >Forgot password?</router-link
        >
      </div>
      <div class="form-buttons-container">
        <ActionButton text="Log in" icon="login" v-if="!hideButton" />
      </div>
    </Form>
  </div>
</template>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SingleRowField from "@/components/ui/SingleRowField.vue";

import AuthService from "@/services/auth.service";

import { Form } from "vee-validate";
import { useToast } from "vue-toastification";
import * as yup from "yup";

import User from "@/models/user";

export default {
  name: "LoginForm",
  components: {
    ActionButton,
    Form,
    SingleRowField,
  },
  props: {
    hideButton: Boolean,
    width: {
      type: String,
      default: "300px",
    },
  },
  emits: ["submit"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const schema = yup.object({
      username: yup.string().required("Username or email is required"),
      password: yup.string().required("Password is required"),
    });
    return {
      schema,
      username: "",
      password: "",
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.$el.requestSubmit();
    },
    handleInvalidSubmit({ errors }) {
      let key = Object.keys(errors)[0];
      this.toast.error(errors[key]);
    },
    async handleSubmit(values) {
      let user = new User();
      user.username = values.username;
      user.password = values.password;
      await AuthService.login(user)
        .then(() => {
          this.$store.dispatch("user/fetchCurrentUser");
        })
        .then(() => {
          this.$emit("submit");
        })
        .catch((error) => {
          this.toast.error(error.data.detail);
        });
    },
  },
};
</script>
