<template>
  <ComponentContainer :width="fieldWidth">
    <Field
      :name="name"
      as="select"
      style="display: none"
      :value="selected[valueProperty]"
      ref="field"
    >
      <option
        v-for="option in options"
        :value="option[valueProperty]"
        :key="option[keyProperty]"
      >
        {{ option.name }}
      </option>
    </Field>
    <StyledSelected :width="fieldWidth" @click="displayDropdown">
      <span style="display: flex; justify-content: space-between; padding: 0px">
        <span>{{ selected.name }}</span>
        <span><IconComponent icon="caret-down" color="black" /></span>
      </span>

      <ItemsContainer v-if="dropdownVisible">
        <StyledItem
          v-for="option in options"
          :value="option[valueProperty]"
          :key="option[keyProperty]"
          :selected="selected == option"
          @click.stop="handleClick(option)"
        >
          {{ option.name }}
        </StyledItem>
      </ItemsContainer>
    </StyledSelected>
  </ComponentContainer>
</template>

<script>
import IconComponent from "@/components/common/IconComponent.vue";
import { Field } from "vee-validate";
import styled from "vue3-styled-components";

const ComponentContainer = styled("div", { width: String })`
  width: ${(props) => props.width};
  border: none;
  margin: 0px 6px;
`;

const ItemsContainer = styled("div")`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #dddddd;
`;

const StyledItem = styled("div", { value: String, selected: Boolean })`
  padding: 6px 10px;
  background-color: ${(props) => (props.selected ? "Gainsboro" : "white")};
  transition-duration: 0.5s;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const StyledSelected = styled("div", { width: String })`
  max-width: ${(props) => props.width};
  height: 19px;
  text-align: left;
  position: relative;
  border: none;
`;

export default {
  name: "DropdownMenu",
  emits: ["select"],
  components: {
    Field,
    IconComponent,
    ItemsContainer,
    StyledItem,
    StyledSelected,
    ComponentContainer,
  },
  props: {
    name: String,
    options: Array,
    valueProperty: String,
    keyProperty: String,
    fieldWidth: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      selected: {},
      dropdownVisible: false,
    };
  },
  methods: {
    handleClick(option) {
      this.selected = option;
      this.dropdownVisible = false;
      // Need to update value directly to force validation
      this.$refs.field.handleChange(option[this.valueProperty]);
      this.$emit("select", option[this.valueProperty]);
    },
    displayDropdown() {
      this.dropdownVisible = true;
    },
  },
};
</script>
