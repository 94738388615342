<template>
  <vue-final-modal z-index="4" v-bind="$attrs" :content-style="contentStyle">
    <h2>Logging out</h2>
    <div class="modal-text-container">
      <p>
        Are you sure you want to log out?
        <span v-if="$attrs.unsavedChanges"> You have unsaved changes!</span>
      </p>
    </div>
    <div class="modal-buttons-container">
      <ActionButton
        @click="modalCancel"
        text="Cancel"
        color="primary"
        icon="xmark"
        hollow
      />
      <ActionButton
        @click="modalSaveAndLogout"
        text="Save and log out"
        color="primary"
        icon="floppy"
        v-if="$attrs.unsavedChanges"
      />
      <ActionButton
        @click="modalLogout"
        text="Log out"
        color="error"
        icon="logout"
      />
    </div>
  </vue-final-modal>
</template>

<script>
import ActionButton from "@/components/ui/ActionButton.vue";

export default {
  name: "LogOutModal",
  components: {
    ActionButton,
  },
  emits: ["saveAndLogout", "logout"],
  computed: {
    contentStyle() {
      if (this.$attrs.unsavedChanges) {
        return "max-width: 650px";
      } else {
        return "max-width: 450px";
      }
    },
  },
  methods: {
    modalSaveAndLogout() {
      this.$emit("saveAndLogout");
      this.$vfm.hideAll();
    },
    modalLogout() {
      this.$emit("logout");
      this.$vfm.hideAll();
    },
    modalCancel() {
      this.$vfm.hideAll();
    },
  },
};
</script>
