<template>
  <div>
    <div style="margin-bottom: 30px">
      <img src="../assets/green-packaging.svg" alt="Green Packaging by Huld" />
      <h2 style="margin-top: 14px">
        {{ headingContent }}
      </h2>
    </div>
    <div style="width: 450px; margin-left: auto; margin-right: auto">
      <p class="helptext" style="text-align: left">{{ helptextContent }}</p>
      <ChangePasswordForm
        v-if="currentPassword"
        :currentPassword="currentPassword"
        @submit="handleChangeSubmit"
      />
      <CheckPasswordForm v-if="!currentPassword" @submit="handleCheckSubmit" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CheckPasswordForm from "@/components/forms/CheckPasswordForm.vue";
import ChangePasswordForm from "@/components/forms/ChangePasswordForm.vue";

export default {
  name: "ChangePasswordView",
  components: {
    ChangePasswordForm,
    CheckPasswordForm,
  },
  data() {
    return {
      currentPassword: null,
    };
  },
  computed: {
    headingContent() {
      let result = "Change your password here - Step ";
      if (this.currentPassword) {
        result += "2";
      } else {
        result += "1";
      }
      return result;
    },
    helptextContent() {
      if (this.currentPassword) {
        return "Create a new password. It should be at least 8 characters long.";
      } else {
        return "Please, insert your current password.";
      }
    },
  },
  methods: {
    handleCheckSubmit(e) {
      this.currentPassword = e.password;
    },
    handleChangeSubmit() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
