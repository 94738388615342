import api from "./api";
const API_PATH = "/v1/packaging-materials/";

class PackagingMaterialService {
  getAllPackagingMaterials() {
    return api.get(API_PATH);
  }

  getAllClasses() {
    return api.get(API_PATH + "classes");
  }

  getPackagingMaterialsFiltered(f) {
    var QUERY_PATH = API_PATH;
    if (f) {
      QUERY_PATH += "?";
      f.forEach((i, idx, array) => {
        QUERY_PATH += "f=";
        QUERY_PATH += i;
        if (idx < array.length - 1) {
          QUERY_PATH += "&";
        }
      });
    }
    return api.get(QUERY_PATH);
  }

  getPackagingMaterialById(id) {
    //return axios.get(API_URL + '/' + id);
    /*await axios.get(API_URL + '/' + id)
            .then(response => {
                return response.data;
            })
            .catch(e => {
                console.error(e);
            });*/
    return api
      .get(API_PATH + id)
      .then((response) => response.data)
      .catch((e) => console.error(e));
  }
}

export default new PackagingMaterialService();
