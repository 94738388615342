<template>
  <div>
    <table>
      <thead>
        <tr class="header">
          <th style="width: 99px">Compare</th>
          <th style="width: 293px">Solution name</th>
          <th style="width: 185px">CO₂ / Package</th>
          <th style="width: 196px">CO₂ / Product</th>
          <th style="width: 126px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="solution in solutions" :key="solution.solutionId">
          <td>
            <input
              v-if="solution.status.isCalculable"
              type="checkbox"
              v-model="solution.selectedForComparison"
              :disabled="isThisCheckboxDisabled(solution)"
              @click="handleSelectClick"
            />
          </td>
          <td>{{ solution.name }}</td>
          <td>{{ solution.getPartsCombinedTotalFormatted() }} CO₂/kg</td>
          <td>{{ solution.getCO2EqPerProductFormatted() }} CO₂/kg</td>
          <td>
            <div
              style="
                display: flex;
                align-content: center;
                justify-content: space-between;
                padding-right: 10px;
              "
            >
              <IconComponent
                style="cursor: pointer; height: 28px"
                icon="pencil28"
                color="black"
                alt="Edit"
                @click="openEditor(solution.solutionId)"
              />
              <IconComponent
                style="cursor: pointer; height: 28px"
                icon="copy28"
                color="black"
                alt="Copy"
                @click="copySolution(solution.solutionId)"
              />
              <IconComponent
                style="cursor: pointer; height: 28px"
                icon="trash28"
                color="black"
                alt="Delete"
                @click="handleDeleteClick(solution.solutionId, solution.name)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <SolutionComparison
      v-show="hasSelectedSolutions"
      :solutions="selectedSolutionsData"
      :width="comparisonWidth"
      ref="bar"
    />
  </div>
</template>

<style scoped>
table {
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: Fira Sans;
  font-weight: 400;
}

tr.header {
  background-color: var(--primary);
  height: 50px;
}

tr {
  height: 48px;
}

th {
  color: white;
  border-top: 1px solid var(--border);
  font-weight: 400;
}

table th:first-child {
  border-top-left-radius: 4px;
  border-left: 1px solid var(--border);
}

table th:last-child {
  border-top-right-radius: 4px;
  border-right: 1px solid var(--border);
}

table tr:nth-child(even) {
  background-color: var(--table-row-bg);
}

tr td:first-child {
  border-left: 1px solid var(--border);
}

tr td:last-child {
  border-right: 1px solid var(--border);
}

table tr:last-child td {
  border-bottom: 1px solid var(--border);
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
  border-left: 1px solid var(--border);
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
  border-right: 1px solid var(--border);
}
</style>

<script>
/* eslint-disable */
import ActionButton from "@/components/ui/ActionButton.vue";
import SolutionComparison from "@/views/SolutionListView/SolutionComparison.vue";
import DeleteSolutionModal from "@/components/modals/DeleteSolutionModal.vue";
import HowToImprove from "@/components/common/HowToImprove.vue";
import IconButton from "@/components/ui/IconButton.vue";
import IconComponent from "@/components/common/IconComponent.vue";
import styled from "vue3-styled-components";

import SolutionService from "@/services/solution.service";

import { $vfm } from "vue-final-modal";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";

const StyledTable = styled("table")`
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
`;

const StyledHeaderRow = styled("tr")`
  background-color: #376aed;
  border-collapse: collapse;
`;

const StyledRow = styled("tr")`
  background-color: #ffcc99;
`;

const StyledCell = styled("td")`
  border: 1px solid #ffa34d;
  padding: 4px;
`;

const ClearCell = styled("td")`
  background-color: white;
  padding: 8px;
  &:last-child {
    &:last-child {
      background-color: red;
    }
  }
`;

export default {
  name: "SolutionListSection",
  components: {
    ActionButton,
    ClearCell,
    SolutionComparison,
    HowToImprove,
    IconButton,
    IconComponent,
    StyledCell,
    StyledHeaderRow,
    StyledRow,
    StyledTable,
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    return { toast, store };
  },
  data() {
    return {
      deletingSolution: {
        solutionId: null,
        name: null,
      },
    };
  },
  mounted() {
    this.$watch("selectedSolutions", (newSolutions) => {
      this.$store.dispatch("selectedSolutions/setup", newSolutions);
    });
  },
  computed: {
    windowWidth() {
      return this.$store.getters["window/getWidth"];
    },
    isMinimized() {
      return this.windowWidth < this.minimizeCutoff;
    },
    solutions: {
      get() {
        return this.$store.getters["userSolutions/getSolutions"];
      },
      set(value) {
        this.$store.dispatch("userSolutions/saveSolutions", value);
      },
    },
    selectedSolutions() {
      return this.$store.getters["userSolutions/getSelectedSolutions"];
    },
    hasSelectedSolutions() {
      return this.selectedSolutions.length > 0;
    },
    selectedSolutionsData() {
      return this.$store.getters["selectedSolutions/getBars"];
    },
    comparisonWidth() {
      return Math.min(400, this.windowWidth - 100);
    },
  },
  methods: {
    copySolution(solutionId) {
      SolutionService.copySolution(solutionId)
        .then(() => {
          this.$store.dispatch("userSolutions/loadSolutions");
        })
        .then(() => {
          this.toast.success("Solution copied successfully!");
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async openEditor(solutionId) {
      await this.$store.dispatch("solution/loadSolution", solutionId);
      this.$router.push({
        name: "loadSolution",
        params: { solutionId: solutionId },
      });
    },
    handleMassSelect(e) {
      this.solutions.forEach((solution) => {
        if (e.target.checked && solution.status.isCalculable) {
          solution.selectedForComparison = true;
        } else {
          solution.selectedForComparison = false;
        }
      });
    },
    isThisCheckboxDisabled(solution) {
      if (
        this.selectedSolutions.length >= 10 &&
        !solution.selectedForComparison
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleSelectClick() {
      //this.$store.dispatch("userSolutions/setChartData", this.solutions);
    },
    handleDeleteClick(solutionId, name) {
      this.deletingSolution = { solutionId: solutionId, name: name };
      const deletingSolution = this.deletingSolution;
      const toast = this.toast;
      const store = this.$store;
      $vfm.show({
        component: DeleteSolutionModal,
        bind: {
          deletingSolution: deletingSolution,
        },
        on: {
          delete() {
            let CSRFToken = store.getters["auth/getCSRFToken"];
            SolutionService.deleteSolution(
              deletingSolution.solutionId,
              CSRFToken
            )
              .then(() => {
                toast.success("Solution deleted successfully!");
              })
              .then(() => {
                store.dispatch("userSolutions/loadSolutions");
              })
              .catch((e) => {
                console.error(e);
              });
          },
        },
      });
    },
  },
};
</script>
